import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import injectSheet from "react-jss";
import Color from "color";
import classnames from "classnames";

export const styles = (theme) => ({
  textarea: {
    "& > .textarea": {
      width: "100%",
      minHeight: (props) => props.minHeight || "130px",
      borderRadius: 20,
      padding: "10px 20px",
      backgroundColor: (props) => props?.backgroundColor || "#000000",
      color: (props) => props?.textColor || "#999999",
      resize: "none",
      border: "1px solid transparent",
      whiteSpace: "wrap !important",
    },
    "& > .textarea::placeholder": {
      color: (props) => props?.placeholderColor || "#999999",
    },
    "& > .textarea:focus": {
      borderColor: `${theme.colors.basic.dark}`,
      boxShadow: `0 0 0 3px ${Color(theme.colors.basic.dark).alpha(0.35)}`,
    },
  },
});

class Textarea extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value || "",
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    e.persist();

    this.setState({
      value: e.target.value,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.value != prevState.value) {
      this.props.onChange &&
        this.props.onChange(this, this.state.value, this.props.name);
    }
  }

  render() {
    const { placeholder, size, classes, className } = this.props;

    const classNames = classnames(
      classes.textarea,
      className,
      "ks--text-input",
      size
    );

    return (
      <div className={classNames}>
        <textarea
          value={this.state.value}
          placeholder={placeholder}
          onChange={this.handleChange}
          className={"textarea input"}
        />
      </div>
    );
  }
}

Textarea.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  placeholderColor: PropTypes.string,
  size: PropTypes.string,
  isMobile: PropTypes.bool,
};

Textarea.defaultProps = {
  value: "",
  onChange: null,
  placeholder: "",
  backgroundColor: null,
  textColor: null,
  placeholderColor: null,
  size: "lg",
  isMobile: false,
};

export default injectSheet(styles)(Textarea);
