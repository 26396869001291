const en_EN = {
  media_type: {
    1: `Song`,
    2: `Radio id`,
    3: `Between song`,
    4: `Commercial`,
    5: `Commercial started`,
    6: `Commercial purpose`,
  },
  mood: {
    1: "Very calm",
    2: "Quiet",
    3: "Normal",
    4: "Moved",
    5: "Very moved",
    6: "Very calm and quiet",
    7: "Quiet and Normal",
    8: "Quiet, Normal and Moving",
    9: "Normal and Moved",
    10: "All",
  },
  languages: {
    1: "Spanish", 
    2: "English",       
    3: "French",
    4: "Portuguese",
    5: "Estonian",
    6: "German",
    7: "Italian"
  },
  label1: "Languages",
  label2: "Song deleted by service provider, please edit the playlist and delete it",
  label3: "Song removed by service provider, please remove from this list",
  label4: "Playing",
  label5: "Loading application...",
  label6: "Control Panel",
  label7: "User (Do not use email)",
  label8: "Password",
  label9: "Login",
  label10: "Error, Please provide username and password.",
  label11: "Error, This account is not an administrator.",
  label12: "Error, User suspended!, contact ",
  label13: "Error, Incorrect username or password.",
  label14: "Error, Your subscription has expired. Go to ",
  label15:
    "Error, Failed to connect to the server, check your internet connection.",
  label16: "Control \nPanel",
  label17: "Users",
  label18: "Create, edit and control \n (local) users",
  label19: "Clone settings \n from one user to others",
  label20: "Customize the logo \n and program data",
  label21: "Create and edit \n user groups",
  label22: "Download \n usage reports",
  label23: "Preferences",
  label24: "Create and edit preferences \n of a user",
  label25: "Create and edit \n user playlist",
  label26: "Assign playlists or hibernations to each \n time of each day",
  label27: "Manage music styles",
  label28: "Manage songs",
  label29: "Advertisements",
  label30: "Upload and manage media",
  label31: "Create, edit and configure clocks",
  label32: "Upload and manage ads",
  label33: "Create, edit, and configure guidelines",
  label34: "Activity",
  label35: "Create and edit preferences",
  label36: "User Groups",
  label37: "Playlist planner",
  label38: "Personalization",
  label39: "Ad planner",
  label40: "Cloned",
  label41: "Sign off",
  label42: "Loading Data...",
  label43: "Read more",
  label44: "Read less",
  label45:
    'In this section you can create users. You need to have one user for each location, and each user can be used at only one site at a time. You can also delete or edit users (change username, password, etc.). \nEach user can be assigned to one or more user groups, to make it easier to mass user configuration. User groups create them yourself in the section "CREATE AND EDIT USER GROUPS".  nYou can also control what is heard in each venue from this section. \nTo assign playlists or advertising guidelines to a user you must go to the corresponding section ("PLAYLISTS" or "ADVERTISEMENTS" )',
  label46: "Hired users",
  label47: "Active users",
  label48: "Available Users",
  label49: "Create user",
  label50: "Write the users or user groups to search...",
  label51: "Filter by...",
  label52: "Sort by...",
  label53: "Name",
  label54: "E-mail",
  label55: "Group",
  label56: "Connected",
  label57: "Disconnected",
  label58: "Never connected",
  label59:
    "January,February,March,April,May,June,July,August,September,October,November,December",
  label60: "Jan,Feb,Mar,Abr,May,Jun,Jul,Aug,Sep,Oct,Nov,Dec",
  label61: "Sunday,Monday,Tuesday,Wednesday,Thursday,Friday,Saturday",
  label62: "Sun,Mon,Tue,Wed,Wed,Thu,Fri,Sat",
  label63: "Su,Mo,Tu,We,Wd,Th,Sa",
  label64: "Connected",
  label65: "Disconnected",
  label66: "User never connected",
  label67: "Suspended by service provider",
  label68: "Active",
  label69: "Playing the playlist",
  label70: "Playlists played",
  label71: "From",
  label72: "Group(s)",
  label73: "Not assigned to a group",
  label74: "Create \n user",
  label75:
    "User names must include the @ symbol. \n If you don't include it, it will be automatically added to the beginning of the username.",
  label76: "Required",
  label77: "Confirm Password",
  label78: "Optional",
  label79: "Description",
  label80: "Save Changes",
  label81: "Saving changes...",
  label82: "Create",
  label83: "Creating...",
  label84:
    "Usernames must include the @ symbol. \n If you don't include it, it will be automatically added to the beginning of the username.",
  label85: "Yes",
  label86: "No",
  label87: "Information about",
  label88: "User",
  label89: "No data at the moment",
  label90: "User created",
  label91: "Lock PIN",
  label92: "You have not been assigned a PIN",
  label93: "Last five (5) connections",
  label94: "Logged out",
  label95: "Paused",
  label96: "Hibernating",
  label97: `You can clone the settings of one user to another or other users, or to a group or groups. You choose the source user and then the target user (s), or the target group or groups. Select the settings to clone and press the "CLONE" button.\n IMPORTANT: The settings that are cloned will erase the settings that the target users have. For example, if we clone the user's playlists, all the playlists created by the target user or the target user group will be deleted.`,
  label98: "Choose source user...",
  label99:
    "(Select the user from which the selected settings are cloned below)",
  label100: "Select users",
  label101: "Select groups",
  label102: "All users",
  label103: "Target user or users",
  label104: "Target group or groups",
  label105:
    "(Select the user or users to whom the selected settings will be cloned below)",
  label106:
    "(Select the group or groups to which the settings selected below will be cloned)",
  label107: "Choose target user or users...",
  label108: "Choose destination group or groups...",
  label109: "Settings to clone",
  label110: "Content manager",
  label111: "(User styles and song modifications)",
  label112: "Clone",
  label113: "Cloning...",
  label114: "Customize the logo \n and program data",
  label115:
    'You can customize the application with your logo and your data. The logo will appear in the login and in the menu, and your data will appear in the "About this application" section.',
  label116: "Select image",
  label117: "Optional (Replaces the application logo)",
  label118: "Upload square image in .jpg or .png format.",
  label119: "Upload image",
  label120: "Restore",
  label121: "Data",
  label122:
    '(They will be displayed in the "About this application" section of the program)',
  label123: "Name of the company or trade",
  label124: "Website",
  label125: "Text",
  label126: "Optional (For example, phone or contact name)",
  label127: "Save",
  label128: "Saving...",
  label129: "Remove",
  label130: "Removing...",
  label131: "User \n logo",
  label132: "Personalization was successfully restored.",
  label133: "Error, something went wrong when resetting customization.",
  label134: "Do you want to reset personalization?",
  label135:
    "The customization will be removed and the default will be restored.",
  label136:
    'Create the groups and then, from "CREATE, CONFIGURE AND CONTROL USERS (LOCAL)" you assign users to the group or groups you want. A user can belong to multiple groups. User groups serve to facilitate the management of large numbers of users by assigning settings such as playlist plans, advertising guidelines, etc.',
  label137: "Write the name of the group to search...",
  label138: "Create group",
  label139: "Create \n group",
  label140: "Edit",
  label141:
    "You can download the reports of used playlists and published announcements according to the dates you want.",
  label142: "Ad reports",
  label143: "Playlist reports",
  label144: "(Only reports related to advertisements will be displayed)",
  label145: "Report type",
  label146: "Choose a report type...",
  label147: "Medium",
  label148: "Choose a medium...",
  label149: "General Report (Detailed)",
  label150: "Report discriminated by media selection (Summary)",
  label151: "Report discriminated by media selection (Detailed)",
  label152: "Initial date",
  label153: "Required (from)",
  label154: "Final date",
  label155: "Required (to)",
  label156: "(Only reports related to the use of playlists will be shown)",
  label157: "Download",
  label158: "Downloading...",
  label159: "Error, something went wrong generating the report.",
  label160: "The report was generated successfully.",
  label161:
    "Error, The report was not generated, there are no records in that time interval.",
  label162: "Report_of_",
  label163: "_(summary)",
  label164: "_(detailed)",
  label165: "User name",
  label166: "Media name",
  label167: "File name",
  label168: "Schedule from",
  label169: "Schedule to",
  label170: "Playback date",
  label171: "Reproduction number",
  label172: "Range from",
  label173: "Range to",
  label174:
    "Assign lock pin, \n Remove songs with inappropriate language, \n Set hibernation.",
  label175: "Choose a user...",
  label176:
    'You can configure different playlists or hibernations for the different time slots for each day of the week. Select a user and then press the "+" symbol that exists on the far right of each day to make your settings.',
  label177: "Error, You have no active accounts, please contact the service provider.",
  label178:
    'In this section you can upload (upload) ads and delete or edit your already uploaded ads. Then, in "CREATE, EDIT AND CONFIGURE GUIDELINES" you can create your advertising guidelines so that the announcements sound in the premises.',
  label179: "Write the name of the ad to search...",
  label180: "Upload",
  label181: "Create, edit \n and configure guidelines",
  label182:
    "You can add ad guidelines for your own ads (previously uploaded) to sound on premises.",
  label183: "Add pattern",
  label184:
    'Create the groups and then, from "CREATE, CONFIGURE AND CONTROL USERS (LOCAL)" you assign users to the group or groups you want. A user can belong to multiple groups.',
  label185: "Load medium",
  label186: "Advertiser",
  label187: "Brand or product",
  label188: "User (Local)",
  label189: "User group",
  label190: "Priority",
  label191: "Number of posts",
  label192: "Media type",
  label193: "To",
  label194: "Locations",
  label195: "Versions",
  label196: "Unlimited",
  label197: "Create, edit \n and configure clocks",
  label198:
    "You can add ad guidelines from your own ads (previously uploaded).",
  label199: "Clocks",
  label200: "Calendar",
  label201: "Manage clocks",
  label202: "Assign clocks to normal day",
  label203: "Assign clocks to special day",
  label204: "Calendar activated",
  label205: "Calendar deactivated",
  label206: "User deleted successfully.",
  label207: "Error, something went wrong deleting the user.",
  label208: "You want to delete the user",
  label209: "User will be removed",
  label210: "Error, username contains blank spaces (not allowed).",
  label211: "Error, the username requires a 6-40 character length.",
  label212: "Error, the password requires a 6-40 character length.",
  label213: "The user was created successfully.",
  label214: "Error, the password and its confirmation are not the same.",
  label215: "Error, the password is required.",
  label216: "Error, username is required.",
  label217: "Edit user",
  label218: "Error, username contains the # character (not allowed).",
  label219: "The user edited successfully.",
  label220: "Error, something went wrong when editing the user.",
  label221: "Cloning was successful.",
  label222: "Error, something went wrong doing the cloning.",
  label223: "Origin user",
  label224: "Error, something went wrong saving the group.",
  label225: "Group successfully saved.",
  label226: "Error, something went wrong while editing the group.",
  label227: "The Group was successfully edited.",
  label228: "The group",
  label229: "was successfully removed.",
  label230: "Error, something went wrong removing the group",
  label231: "You want to delete the group",
  label232: "The group will be deleted",
  label233: "The customization was saved successfully.",
  label234: "Error, something went wrong saving customization.",
  label235: "Show favorites",
  label236: "Show or hide the favorites bar (bottom bar)",
  label237: "Add lock PIN",
  label238: "Change lock PIN",
  label239: "You have no lock PIN",
  label240: "Your lock PIN is",
  label241: "Add PIN",
  label242: "Modify PIN",
  label243: "Lock now",
  label244: "To lock the app now require a PIN",
  label245: "Lock the app now",
  label246: "Block on app launch",
  label247: "To start the application always locked require a PIN",
  label248: "The application will always start blocked",
  label249: "Change language",
  label250: "Change the language of the application",
  label251: "Show buttons in playing",
  label252: "Show or hide buttons (do not program, always program, edit, add)",
  label253: "Enable restriction to explicit content",
  label254:
    "The programming of songs with inappropriate or abusive content is restricted",
  label255: "Hibernate mode",
  label256: "Edit hibernate settings",
  label257: "Add a hibernate configuration",
  label258: "Add",
  label259: "The app language was changed to English successfully.",
  label260:
    "Error, something went wrong when changing the application language to English.",
  label261:
    "The language of the application was changed to Spanish successfully.",
  label262:
    "Error, something went wrong when changing the language of the application to Spanish.",
  label263: "The application language was changed to French successfully.",
  label264:
    "Error, something went wrong when changing the application language to French.",
  label265: "Favorites bar successfully activated.",
  label266: "Favorites bar successfully deactivated.",
  label267: "Error, something went wrong activating the bookmarks bar.",
  label268: "Error, something went wrong deactivated the bookmarks bar.",
  label269: "The application has been blocked successfully.",
  label270: "The app has been successfully unlocked.",
  label271: "Error, something went wrong when locking the app.",
  label272: "Error, something went wrong unlocking the app.",
  label273: "Lock on app launch enabled successfully.",
  label274: "Crash on app launch disabled successfully.",
  label275:
    "Error, something went wrong when activating the lock when starting the application.",
  label276:
    "Error, something went wrong when disabling the lock when starting the application.",
  label277: "Playback buttons activated.",
  label278: "Playback buttons disabled.",
  label279: "Error, something went wrong activating buttons in playback.",
  label280: "Error, something went wrong disabling buttons in play.",
  label281:
    "Activated restricts the programming of songs with inappropriate or abusive content.",
  label282:
    "Off restricts the programming of songs with inappropriate or abusive content.",
  label283:
    "Error, something went wrong when activating the explicit content restriction.",
  label284:
    "Error, something went wrong turning off the explicit content restriction.",
  label285: "Hibernate mode activated.",
  label286: "Hibernate mode disabled.",
  label287: "Error, something went wrong activating hibernate mode.",
  label288: "Error, something went wrong turning off hibernate.",
  label289: "Add \n PIN",
  label290: "Edit \n PIN",
  label291: "Current PIN",
  label292: "New PIN",
  label293: "Mandatory (PIN must be 4 digits)",
  label294: "Confirm your new PIN",
  label295: "Error, only numbers are allowed in your PIN and 0000 is not allowed",
  label296: "Error, the PIN must be 4 digits.",
  label297: "Error, the confirmation is not equal to the new PIN.",
  label298:
    "Error, the PIN is the same as the previous one, please enter a new PIN.",
  label299: "PIN successfully saved.",
  label300: "PIN modified successfully.",
  label301: "Error, something went wrong while saving the PIN.",
  label302: "Error, something went wrong while modifying the PIN.",
  label303: "Add a hibernate \n configuration",
  label304: "Edit a hibernate \n configuration",
  label305: "State",
  label306: "Hibernation schedule",
  label307: "In",
  label308: "Every day",
  label309: "Specified days",
  label310: "The hibernation configuration was saved successfully.",
  label311: "Error, something went wrong saving hibernate settings.",
  label312: "The hibernate configuration was edited successfully.",
  label313: "Error, something went wrong editing hibernate settings.",
  label314: "Planner successfully activated.",
  label315: "Planner successfully deactivated.",
  label316: "Error, something went wrong activating the planner.",
  label317: "Error, something went wrong deactivating the planner.",
  label318: "Planner Activated",
  label319: "Planner Deactivared",
  label320: "Add plan",
  label323: "My playlists",
  label324: "Random",
  label325: "Guideline successfully saved.",
  label326: "Error, something went wrong saving the guideline.",
  label327: 'Error, "From" has to be smaller than "To".',
  label328: "Error, a plan already exists in that time schedule.",
  label329: "Upload Ad",
  label330: "The plan was successfully removed.",
  label331: "Error, something went wrong removing the plan.",
  label332: "Random mode successfully activated.",
  label333: "Random mode successfully deactivated.",
  label334: "Error, something went wrong activating random mode.",
  label335: "Error, something went wrong deactivating random mode.",
  label336: "Drag \n your ad \n or click \n in this area",
  label337: "Size",
  label338: "(MP3 only. Maximum size 5 MB).",
  label339: "Upload ad",
  label340: "Uploading ad...",
  label341: "Error, (ONLY MP3 FILES).",
  label342: "Ad name must be 1-50 characters long.",
  label343:
    "Error, something went wrong uploading the ad, check that it is an MP3 file and that its size is not larger than 5 MB.",
  label344: "The ad was successfully uploaded.",
  label345: "Ad",
  label346: "Error, something went wrong removing the ad",
  label347: "You want to delete the ad",
  label348: "The ad will be removed",
  label349: "Everyday",
  label350: "Monday to friday",
  label351: "Saturday and Sunday",
  label352: "Exact day",
  label353: "Exact time",
  label354: "Interval",
  label355: "Each",
  label356: "Frequency",
  label357: "songs",
  label358: "Ad limit per batch",
  label359: "Schedule beginning",
  label360: "Schedule end",
  label361: "Add \n guideline",
  label362: "Days",
  label363: "Schedule",
  label364: "Rank of time",
  label365: "Ad limit per batch",
  label366: "Every x minutes",
  label367: "Every x songs",
  label368: "Day or days",
  label369: "Hour",
  label370: "Day",
  label371: "Define start",
  label372: "Define end",
  label373: "Error, there is already a guideline with that name.",
  label374: "Error, there is already a guideline in that time interval.",
  label375: "Error, there is already a guideline for that exact time.",
  label376: "The middle",
  label377: "Error, something went wrong removing the medium",
  label378: "You want to remove the medium",
  label379: "The medium will be removed",
  label380: "to",
  label381: "High",
  label382: "Normal",
  label383:
    "You can upload any type of media (spot, ad, between song, radio ID) \n or create your own type. You choose the file to load, you give it a name, you indicate what type of media it is, \n you assign all the values, if there are versions you can load them and you indicate the locations where it will sound.",
  label384: "Define start and end",
  label385: "Choose…",
  label386: "Start",
  label387: "End",
  label388: "Brand or product",
  label389: "Advertiser company",
  label390: "Values",
  label391: "Infinite",
  label392: "Places where \n will be published",
  label393: "All users (local)",
  label394: "Select users (local) or groups",
  label395: "Users (Local)",
  label396: "Groups",
  label397: `Error, something went wrong loading the media.`,
  label398: `The medium was saved successfully.`,
  label399: `Error, you must select a priority.`,
  label400: `Error, you did not select any location to post.`,
  label401: `Error, you must select a brand or product.`,
  label402: `Error, you must select an advertiser.`,
  label403: `Error, you must select a media type.`,
  label404: `Error, you must add a media name.`,
  label405: `Filter by`,
  label406: `Drag the \n users or groups here`,
  label407: `Error, Something went wrong when editing media.`,
  label408: `The medium was successfully edited.`,
  label409: `Add \n media type`,
  label410: `Error, something went wrong saving the media type.`,
  label411: `The Media Type was successfully saved.`,
  label412: `Error, the media type name must contain 1 to 50 characters.`,
  label413: `Add \n advertiser`,
  label414: `Error, something went wrong saving the advertiser.`,
  label415: `The advertiser was successfully saved.`,
  label416: `Error, advertiser name must contain 1 to 50 characters.`,
  label417: `Add \n brand or product`,
  label418: `Type here to search ...`,
  label419: `Error, something went wrong saving the brand or product.`,
  label420: `The brand or product was successfully saved.`,
  label421: `Error, the name of the brand or product must contain from 1 to 50 characters.`,
  label422: `Load \n versions to`,
  label423: `The version`,
  label424: `Error, something went wrong removing the version`,
  label425: `You want to delete the version`,
  label426: `Version will be removed`,
  label427: `Cancel`,
  label428: `Error, something went wrong loading the version.`,
  label429: `The version loaded successfully.`,
  label430: `Error. The version could not be loaded, close the window and try again.`,
  label431: `Calendar activated successfully.`,
  label432: `Calendar disabled successfully.`,
  label433: `Error, something went wrong activating the calendar.`,
  label434: `Error, something went wrong deactivating the calendar.`,
  label435: `Create Clock`,
  label436: `To create a clock, you will need to drag the media that appears on the left side to the right. Then you can drag them up or down to order them. The "COMMERCIAL" medium has 16 commercials (spots or advertisements) by default. The rest have only 1 medium by default. In both cases, this figure can be edited by pressing the pencil.`,
  label437: `Media finder`,
  label438: `Drag the \n media here`,
  label439: `Error, clock name must contain 1 to 50 characters.`,
  label440: `Error, something went wrong saving the watch.`,
  label441: `The watch was successfully saved.`,
  label442: `Error, you have not added any media.`,
  label443: `Manage \n clocks`,
  label444: `Edit or delete, if you delete the clock disappears from the calendar.`,
  label445: `The watch`,
  label446: `Error, something went wrong removing the clock`,
  label447: `You want to delete the clock`,
  label448: `The clock will be removed`,
  label449: `Quantity`,
  label450: `Blocks of`,
  label451: `Error, something went wrong editing`,
  label452: `To edit a clock, you must drag the media that appears on the left side to the right. Then you can drag them up or down to order them. The "COMMERCIAL" medium has 16 commercials (spots or advertisements) by default. The rest have only 1 medium by default. In both cases, this figure can be edited by pressing the pencil.`,
  label453: `The watch was successfully edited.`,
  label454: `Assign a clock to each time zone. You can leave sections without assigning a clock, in that case, only music will be heard (without media).`,
  label455: `No clock assigned`,
  label456: `Error, something went wrong saving the watch on a normal day.`,
  label457: `The watch on the normal day was successfully saved.`,
  label458: `Assign clocks to special day`,
  label459: `Repetitions`,
  label460: `Punctual day`,
  label461: `Days range`,
  label462: `Error, the name of the special day must contain from 1 to 50 characters.`,
  label463: `The watch on the special day was successfully saved.`,
  label464: `Error, something went wrong saving the watch on a special day.`,
  label465: `Error, there is a clock assigned to that specific day and its repetition coincides or is within another range.`,
  label466: `Error, there is a clock assigned in that range.`,
  label467: `Error, the end date is greater than the start date.`,
  label468: `Edit or delete \n structure of the day`,
  label469: `Edit or delete, when changing any value of the selectors it will be edited automatically, if you delete the day it will disappear from the calendar.`,
  label470: `This interval was successfully updated in the day structure.`,
  label471: `Error. something went wrong editing this interval.`,
  label472: `Last connection`,
  label473: `Connected the `,
  label474: `Finalize`,
  label475: `Manage \n media`,
  label476: `Manage \n advertising companies`,
  label477: `Manage \n brands or products`,
  label478: `Edit or delete, you will not be able to delete a type of media already assigned to a media already loaded.`,
  label479: `Edit or delete, you will not be able to delete a company or advertiser already assigned to an already loaded medium.`,
  label480: `Edit or delete, you will not be able to delete a brand or product already assigned to some media already loaded.`,
  label481: `Media Type`,
  label482: `The company or advertiser`,
  label483: `The brand or product`,
  label484: `Error, something went wrong removing the media type`,
  label485: `Error, something went wrong eliminated the company or advertiser`,
  label486: `Error, something went wrong removing the brand or product`,
  label487: `You want to remove the media type`,
  label488: `You want to delete the company or advertiser`,
  label489: `You want to remove the brand or product`,
  label490: `Media type will be removed`,
  label491: `The company or advertiser will be removed`,
  label492: `The brand or product will be removed`,
  label493: `Ok`,
  label494: `Warning`,
  label495: `You cannot delete this type of media, since it is assigned to one or more media already loaded, if you want to delete it you must first delete the media associated with it.`,
  label496: `You cannot delete this company or advertiser, since it is assigned to one or more media already loaded, if you want to delete it you must first delete the media associated with it.`,
  label497: `You cannot delete this brand or product, since it is assigned to one or more media already loaded, if you want to delete it you must first delete the media associated with it.`,
  label498: `You have exceeded the limit of means to upload, available for your subscription, please contact us to extend the limitations of your subscription.`,
  label499: `You have exceeded the limit of versions available for your subscription, please contact us to extend the limitations of your subscription.`,
  label500: `You have exceeded the limit of the ad size available for your subscription, please contact us to extend the limitations of your subscription.`,
  label501: `You have exceeded the limit of the type of media that you can create, available for your subscription, please contact us to extend the limitations of your subscription.`,
  label502: `Currently your media type creation limit is: `,
  label503: `Currently your media upload limit is: `,
  label504: `Currently your version upload limit is: `,
  label505: `Currently your size limit per ad is: `,
  label506: `You want to delete the pattern`,
  label507: `Pattern will be removed`,
  label508: `Add / remove`,
  label509: `Number of ads loaded`,
  label510: `Add or remove ads`,
  label511: `Add or remove ads, you must drag the ads that appear on the left side to the right. You can then drag them up or down to sort them.`,
  label512: `Ads for the guideline`,
  label513: `Ad finder`,
  label514: `Drag the \n ads here`,
  label515: `Error, something went wrong saving the pattern.`,
  label516: `The pattern was saved successfully.`,
  label517: `Error, something went wrong editing the Pattern.`,
  label518: `The guideline was successfully edited.`,
  label519: `Report discriminated by media selection already deleted (Summary)`,
  label520: `Report discriminated by media selection already deleted (Detailed)`,
  label521: `Half already erased`,
  label522: `My Styles`,
  label523: `You can create your own musical styles and then go to "MANAGE SONGS" and assign the songs that you want to belong to that or those styles.`,
  label524: `Write the name of the style to search...`,
  label525: `The style`,
  label526: `Error, something went wrong removing the style`,
  label527: `You want to remove the style`,
  label528: `The style will be removed`,
  label529: `Create \n style`,
  label530: `Create style`,
  label531: `Error, something went wrong saving the style.`,
  label532: `The style was successfully saved.`,
  label533: `Error, style name must contain 1 to 50 characters.`,
  label534: `Error, something went wrong when editing the style.`,
  label535: `The style was successfully edited.`,
  label536: `Edit \n style`,
  label537: `Song manager`,
  label538: `You can change the style of a song to one created by the user, you can change the name of the song, indicate it as explicit (inappropriate language) or mark it as favorite or not desired. You can also assign it to a manual playlist.`,
  label539: `Write here the name of the song or artist to search...`,
  label540: `Search`,
  label541: `Show`,
  label542: `All`,
  label543: `Only Edited`,
  label544: `Favorites`,
  label545: `Unwished`,
  label546: `Edit \n song`,
  label547: `Predetermined`,
  label548: `Error, The name and password fields are required.`,
  label549: `Original name`,
  label550: `Musical style`,
  label551: `Mood`,
  label552: `Preference`,
  label553: `Explicit song`,
  label554: `(inappropriate or abusive content)`,
  label555: `Error, something went wrong when editing the song.`,
  label556: `The song was successfully edited.`,
  label557: `Permissions to use the music app, press the save button to apply the changes.`,
  label558: `Do you want to unmark this song as a favorite`,
  label559: `You want to bookmark this song`,
  label560: `it will no longer always be included just normally.`,
  label561: `It will always be included in the playlists associated with the style of this song.`,
  label562: `Error, something went wrong when I unmarked this song as a favorite.`,
  label563: `Wrong, something went wrong bookmarking this song.`,
  label564: `The song was successfully unmarked as a favorite.`,
  label565: `The song was successfully bookmarked.`,
  label566: `You want to unmark this song as spam`,
  label567: `You want to mark this song as spam`,
  label568: `it will be heard again as normal.`,
  label569: `it will not be heard again.`,
  label570: `Error, something went wrong when unmarking this song as unwanted.`,
  label571: `Error, something went wrong marking this song as spam.`,
  label572: `The song was successfully unmarked as spam.`,
  label573: `The song was successfully marked as spam.`,
  label574: `Choose playlist ...`,
  label575: `Country`,
  label576: `City`,
  label577: `Province`,
  label578: `Error, The name is already in use, please choose another name.`,
  label579: `You can create your own playlists, in two different ways. Automatic: you choose the styles and the program chooses the songs. Manual: you choose the songs one by one. You can also delete playlists or modify existing ones.`,
  label580: `The playlist`,
  label581: `Error, something went wrong deleting the playlists`,
  label582: `You want to delete the playlist`,
  label583: `The playlist will be deleted`,
  label584: `Playlist Manual`,
  label585: `Automatic Playlist`,
  label586: `Write here the name of the playlist to search...`,
  label587: `Create playlist`,
  label588: `Guideline Ads`,
  label589: `Create \n playlist`,
  label590: `Choose an option.`,
  label591: `Automatic`,
  label592: `Manual`,
  label593: `you choose the styles and the program chooses the songs.`,
  label594: `you choose the songs one by one.`,
  label595: `Select the type of playlist to create...`,
  label596: `Name of the new playlist (required)...`,
  label597: `Description (optional)...`,
  label598: `Next`,
  label599: `Drag the styles you want from left to right. You can drag a style multiple times so that \n more songs of that style are programmed. Then, in the right panel, you can drag the styles up \n and down to sort. \n You can also modify the Style settings, so that it can program songs that are quieter or more moved, etc.`,
  label600: `Write here the name of the style to search...`,
  label601: `Musical Styles Finder`,
  label602: `Styles`,
  label603: `My styles`,
  label604: `Duration (in hours)`,
  label605: `Restrict explicit`,
  label606: `Drag the styles here`,
  label607: `Error, something went wrong creating the automatic playlist.`,
  label608: `The automatic playlist was created successfully.`,
  label609: `Error, there is no style selected to create the playlist.`,
  label610: `Edit \n automatic playlists`,
  label611: `Drag the styles you want from left to right. You can drag a style multiple times so that \n more songs of that style are programmed. Then, in the right panel, you can drag the styles up \n and down to sort. \n You can also modify the Style settings, so that it can program songs that are quieter or more moved, etc.`,
  label612: `Error, something went wrong editing the automatic playlist.`,
  label613: `The automatic playlist was edited successfully.`,
  label614: `Error, the name of the playlists must contain at least one character.`,
  label615: `Modify`,
  label616: `Only successes`,
  label617: `Successes and recommended`,
  label618: `Successes and recommended and varied`,
  label619: `Search for the songs you want, by name or by the name of the artist. Then \n drag each song from left to right. On the right panel, you can drag up \n and down the songs to sort them.`,
  label620: `Song Finder`,
  label621: `Drag the songs here`,
  label622: `Error, something went wrong creating the manual playlist.`,
  label623: `The manual playlist was created successfully.`,
  label624: `Error, the limit of songs per playlist is`,
  label625: `Edit \n manual playlist`,
  label626: `Error, something went wrong editing the manual playlist.`,
  label627: `The manual playlists were edited successfully.`,
  label629: `Number of songs`,
  label630: `(Administrator) - `,
  label631: `(User) -`,
  label632: `to manual playlists`,
  label633: `They can only be added to manual playlists created by you.`,
  label634: `Destination Playlists`,
  label635: `Adding...`,
  label636: `The song was added successfully.`,
  label637: `Error, something went wrong adding the song.`,
  label638: `Choose a group...`,
  label639: `Connection type`,
  label640: `Choose a connection type...`,
  label641: `Spot search`,
  label642: `Results: `,
  label643: `Apply Filter`,
  label644: `Write here the username to search for...`,
  label645: `Non-explicit songs`,
  label646: `Explicit songs`,
  label647: `Choosing a style...`,
  label648: `Choose a user style ...`,
  label649: `Choose a mood ...`,
  label650: `Explicit content`,
  label651: `Choose content type ...`,
  label652: `User criteria`,
  label653: `User Styles`,
  label654: `Audio file error.`,
  label655: `Current password`,
  label656: `Country`,
  label657: `Province`,
  label658: `City`,
  label659: `Hibernation`,
  label660: `Error, the from time must be greater than the to time.`,
  label661: `Error, the difference between hours must be at least 5 minutes.`,
  label662: `Error, you must select at least one day.`,
  label663: `Error, there is (`,
  label664: `) plan(s) that interfere with the time slot you want to create on the selected day(s).`,
  label665: `All plans for the day were deleted, `,
  label666: `Error, something went wrong when deleting all the plans of the day, `,
  label667: `want to delete all the plans of the day?`,
  label668: `All plans for this day will be deleted, `,
  label669: `Overall app volume`,
  label670: `The volume scale of the app goes from 0 to 100`,
  label671: `The volume of the app has been successfully updated.`,
  label672: `Error updating app volume.`,
  label673: `Mute the app`,
  label674: `Unmute the app`,
  label675: `Turn off the sound of the app`,
  label676: `Activate the sound of the app`,
  label677: "The app has been successfully muted.",
  label678: "The app has been successfully unmuted.",
  label679: "Error, something went wrong while muting the app.",
  label680: "Error, something went wrong while unmuting the app.",
  label681: "Reset all",
  label682: "Your users' personalizations were successfully restored.",
  label683:
    "Error, something went wrong resetting your users' personalization.",
  label684: "Do you want to reset the personalization of your users?",
  label685:
    "Personalization will be removed from your users and the default will be restored to each of them.",
  label686: "Without description",
  root: {
    1: "GOD",
    2: "SUPER-ROOT",
    3: "ROOT",
    4: "SUPER-ADMINISTRATOR",
    5: "ADMINISTRATOR",
    6: "SUPERVISOR",
    7: "ADMINISTERED-USER",
    8: "INDIVIDUAL-USER",
  },
  label687: "Account",
  label688: "Account management",
  label689: "Change Password",
  label690: "Change account data (Company name, address, CIF/NIF, etc.)",
  label691: "Download invoices or update debit/credit card details",
  label692: "Change or cancel the subscription",
  label693: "Changing the password of the current account.",
  label694: "New Password",
  label695: "The password was changed successfully.",
  label696: "Error, something went wrong changing the password in amember.",
  label697: "Error, something went wrong while changing the password.",
  label698:
    "Install with Administrator permissions. In Windows 10 simply enter the administrator username and password when installing the program. In Windows 7, the account in which the installation is done must be an administrator. If you want to install in a standard (non-administrator) account, you must transform said user into an administrator, install the program and then transform it back into a standard user. In any case, you must check that no program blocks the app from accessing the Internet (Antivirus, Firewall, etc.).",
  label699:
    'To install the program on a Mac OS computer, do the following: Go to System Preferences Security & Privacy. Click the lock on the bottom left and enter your user password to unlock your Mac. Check the "Anywhere" box in the "Allow third-party apps" area.',
  label700:
    "We recommend installing the app and activating automatic updates, directly from the Google Play Store, in the case of installation outside the store, the updates of the application will be managed by you, checking the latest version of the application published on this page.",
  label701: "Downloads",
  label702: "Important",
  label703:
    "This area is public, no password is needed to get here, I copied the link of this page below and share it with all your users who need to download the program.",
  label704: "Latest version of the app",
  label705: "Go",
  label706: "Instructions",
  label707: "Technical requirements",
  label708:
    'Windows 7 operating system or higher (Windows 10 recommended), the application cannot be used in the Windows N version, since it does not have "Media Feature Pack", consult the support area in this case.',
  label709: "16 GB of available hard disk space (32 GB recommended or higher)",
  label710: "4 GB of RAM or higher",
  label711: "Sound card",
  label712: "Internet connection",
  label713: "ARM or ARM64 processor architecture",
  label714: "2 GB of RAM (4 GB recommended or higher)",
  label715: "Copy Download Area URL",
  label716: "Copy web player URL",
  label717: "Support",
  label718:
    "For <font color=#FFFFFFF'>instructions</font> on how to use the platform and <font color=#FFFFFFF'>frequently asked questions</font>, please",
  label719: "Help and frequently asked questions",
  label720: "More Options",
  label721: "More Information",
  label722: "Certificate",
  label723: "Download area URL, successfully copied.",
  label724: "Web player URL, successfully copied.",
  label725: "Refresh, undo changes",
  label726: "Reset the personalization of all your users",
  label727: "Download certificate",
  label728: "certificate_freedom",
  label729: "Information",
  label730:
    "Mac OS Catalina (10.15.7) or higher (compatible also with versions lower than Catalina but with manual update of the program)",
  label731: "Music",
  label732: "Do you want to log out?",
  label733: "Contact by ticket",
  label734: "Contact by WhatsApp",
  label735: "User suspended by administrator",
  label736: "Suspend",
  label737: "Activate",
  label738: "Suspended by administrator",
  label739: "The user has been successfully activated",
  label740: "The user has been successfully suspended",
  label741: "Error, something went wrong while activating the user",
  label742: "Error, something went wrong while suspending the user",
  label743: "Favorite playlists",
  label744: "Suspend to",
  label745: "Activate to",
  label746: "Do you want to temporarily suspend this user?",
  label747: "Do you want to activate this user?",
  label748: "App Permissions",
  label749: "Permission has been successfully activated",
  label750: "Permission has been successfully disabled",
  label751: "Error activating permission",
  label752: "Error disabling permission",
  label753: "Request PIN activated",
  label754: "Request PIN disabled",
  label755: "Error activating PIN request",
  label756: "Error disabling PIN prompt",
  label757: "Request PIN, On",
  label758: "Request PIN, Off",
  label759: "Add",
  label760: "The playlist was successfully added to favorites",
  label761: "It was not added because the playlist already exists in favorites",
  label762: "Error, something went wrong when adding the playlist to favorites",
  label763: "Adding...",
  label764: "Add to favorite playlists",
  label765: "Alias",
  label766: "Error, password contains blank spaces (not allowed).",
  label767: "Error, you are required to confirm your password.",
  label768: "Application permissions",
  label769: "Licenses",
  label770: "Suspended users",
  label771: "The application language was changed to Portuguese successfully.",
  label772:
    "Error, something went wrong when changing the application language to Portuguese.",
  label773: "Blocked Interpreters",
  label774: "Add to blocked performers",
  label775: "Write the name of the interpreter to search...",
  label776: "The interpreter was successfully added to blocked interpreters",
  label777:
    "Interpreter was not added, it already exists in blocked interpreters",
  label778:
    "Error, something went wrong when adding the interpreter to blocked interpreters",
  label779: "The interpreter",
  label780: "was successfully removed from blocked interpreter.",
  label781:
    "Error, something went wrong removing the interpreter from this list",
  label782: "You want to remove blocked interpreters,",
  label783: "The interpreter will be removed",
  label784: "of interpreter blocked.",
  label785: "Setting",
  label786: "Invalid subscription, contact service provider",
  label787: `Error, You have reached the limit of contracted accounts.`,
  label788: `Error, something went wrong while creating the user.`,
  label789: "You do not have permission to create, edit, or delete users.",
  label790: "You do not have permission to access or view licenses.",
  label791: "You do not have permission to reset personalization for all users.",
  label792: "You do not have permission to view a user's favorite playlist.",
  label793: "You do not have permission to add or remove a user's favorite playlist.",
  label794: "You do not have permission to view a user's playlist scheduler.",
  label795: "You do not have permission to add, edit, or delete playlists in a user's playlist scheduler.",
  label796: "You do not have permission to view a user's ad scheduler.",
  label797: "You do not have permission to add, edit, or delete ads in a user's ad scheduler.",
  label798: "You do not have permission to clone users.",
  label799: "You do not have permission to access user preferences.",
  label800: "You do not have permission to access user personalization.",
  label801: "You do not have permission to access user app permissions.",
  label802: "You do not have permission to suspend a user.",
  label803: "You do not have permission to view the administrator's playlists.",
  label804: "You do not have permission to create, edit, or delete the administrator's playlists.",
  label805: "You do not have permission to view the administrator's favorite playlists.",
  label806: "You do not have permission to add or remove the administrator's favorite playlists.",
  label807: "You do not have permission to view the administrator's styles.",
  label808: "You do not have permission to create, edit, or delete the administrator's styles.",
  label809: "You do not have permission to access the song manager.",
  label810: "You do not have permission to view blocked performers.",
  label811: "You do not have permission to add or remove blocked performers.",
  label812: "You do not have permission to view uploaded ads.",
  label813: "You do not have permission to upload or delete ads.",
  label814: "You do not have permission to access support and downloads.",
  label815: "Edit Selection",
  label816: "Select All",
  label817: "Supervisors",
  label818: "Create or edit supervisors and limit their actions.",
  label819: "Permissions were successfully saved.",
  label820: "Error, something went wrong while saving permissions.",
  label821: "Supervisor suspended by the administrator.",
  label822: "The style was changed for the selected songs.",
  label823: "The selected songs were changed to the default style.",
  label824: "The selected songs were added to the selected playlists.",
  label825: "The selected songs were successfully marked as explicit.",
  label826: "The selected songs were successfully unmarked as explicit.",
  label827: "The selected songs were successfully marked as 'liked'.",
  label828: "The selected songs were successfully unmarked as 'liked'.",
  label829: "The selected songs were successfully marked as 'disliked'.",
  label830: "Modifications were successfully deleted for the selected songs.",
  label831: "Error, something went wrong while changing the style of the songs.",
  label832: "Error, something went wrong while changing the songs to the default style.",
  label833: "Error, something went wrong while adding the songs to the playlists.",
  label834: "Error, something went wrong while marking the songs as explicit.",
  label835: "Error, something went wrong while unmarking the songs as explicit.",
  label836: "Error, something went wrong while marking the songs as 'liked'.",
  label837: "Error, something went wrong while unmarking the songs as 'liked'.",
  label838: "Error, something went wrong while marking the songs as 'disliked'.",
  label839: "Error, something went wrong while deleting modifications for the songs.",
  label840: "Choose an action...",
  label841: "Applying...",
  label842: "Apply",
  label843: "Change to a user style",
  label844: "Change to default style",
  label845: "Add to a manual playlist",
  label846: "Mark as explicit",
  label847: "Unmark as explicit",
  label848: "Mark as liked",
  label849: "Unmark as liked",
  label850: "Mark as disliked",
  label851: "Delete modifications",
  label852: "Selected songs",
  label853: "Action to be taken",  
  label854: "Permissions",
  label855: "Error, something went wrong while editing the supervisor",
  label856: "Edit the supervisor",
  label857: "Observation",
  label858: "Error, the username field is required",
  label859: "Error, you have reached the allowed limit of supervisors.",
  label860: "Error, something went wrong while creating the supervisor.",
  label861: "Create supervisor",
  label862: "Permissions for using the control panel, press the save button to apply changes.",
  label863: "The limit of supervisors you can create is 5.",
  label864: "Type the name of the supervisor to search...",
  label865: "Error, something went wrong while deleting the supervisor",
  label866: "Do you want to delete the supervisor",
  label867: "The supervisor will be deleted",
  label868: "Do you want to temporarily suspend this supervisor?",
  label869: "Do you want to activate this supervisor?",
  label870: `Preparing the ad...`,
  label871: ``,
  label872: ``,
  label873: ``,
  label874: ``,
  label875: ``
};

export default en_EN;
