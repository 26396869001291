const de_DE = {
  media_type: {
    1: `Lied`,
    2: `Radio-ID`,
    3: `Zwischenlied`,
    4: `Werbung`,
    5: `Werbung gestartet`,
    6: `Werbung beendet`,
  },
  mood: {
    1: "Sehr ruhig",
    2: "Ruhig",
    3: "Normal",
    4: "Lebhaft",
    5: "Sehr lebhaft",
    6: "Sehr ruhig und Ruhig",
    7: "Ruhig und Normal",
    8: "Ruhig, Normal und Lebhaft",
    9: "Normal und Lebhaft",
    10: "Alle",
  },
  languages: {
    1: "Spanisch",  
    2: "Englisch",      
    3: "Französisch",
    4: "Portugiesisch",
    5: "Estnisch",
    6: "Deutsch",
    7: "Italienisch"
  },  
  label1: "Sprachen",
  label2: "Song vom Dienstanbieter gelöscht. Bitte bearbeiten Sie die Playlist und löschen Sie sie",
  label3: "Lied vom Dienstanbieter entfernt, bitte aus dieser Liste entfernen",
  label4: "Wiedergabe",
  label5: "Anwendung wird geladen...",
  label6: "Steuerpult",
  label7: "Benutzer (Keine E-Mail verwenden)",
  label8: "Passwort",
  label9: "Anmelden",
  label10: "Fehler, Bitte geben Sie Benutzernamen und Passwort an.",
  label11: "Fehler, Dieses Konto ist kein Administrator.",
  label12: "Fehler, Benutzer gesperrt! Kontaktiere ",
  label13: "Fehler, Falscher Benutzername oder Passwort.",
  label14: "Fehler, Ihr Abonnement ist abgelaufen. Geh zu ",
  label15: "Fehler, Verbindung zum Server fehlgeschlagen. Überprüfen Sie Ihre Internetverbindung.",
  label16: "Steuerpult",
  label17: "Benutzer",
  label18: "Benutzer erstellen, bearbeiten und steuern (lokal)",
  label19: "Konfiguration von einem Benutzer auf andere klonen",
  label20: "Logo und Programmdaten anpassen",
  label21: "Benutzergruppen erstellen und bearbeiten",
  label22: "Nutzungsberichte herunterladen",
  label23: "Einstellungen",
  label24: "Benutzereinstellungen erstellen und bearbeiten",
  label25: "Benutzer-Playlists erstellen und bearbeiten",
  label26: "Playlists oder Standby-Zeiten jedem Tag zuordnen",
  label27: "Musikstile verwalten",
  label28: "Songs verwalten",
  label29: "Anzeigen",
  label30: "Medien hochladen und verwalten",
  label31: "Uhren erstellen und konfigurieren",
  label32: "Anzeigen hochladen und verwalten",
  label33: "Richtlinien erstellen, bearbeiten und konfigurieren",
  label34: "Aktivität",
  label35: "Einstellungen erstellen und bearbeiten",
  label36: "Benutzergruppen",
  label37: "Playlist-Planer",
  label38: "Anpassung",
  label39: "Anzeigen-Planer",
  label40: "Klonen",
  label41: "Abmelden",
  label42: "Daten werden geladen...",
  label43: "Mehr lesen",
  label44: "Weniger lesen",
  label45: 'In diesem Abschnitt können Sie Benutzer erstellen. Es ist erforderlich, für jeden Standort einen Benutzer zu haben, und jeder Benutzer kann jeweils nur an einem Ort verwendet werden. Sie können Benutzer auch löschen oder bearbeiten (Benutzernamen, Passwort usw. ändern).\nJeder Benutzer kann einem oder mehreren Benutzergruppen zugewiesen werden, um die Massenkonfiguration von Benutzern zu erleichtern. Die Benutzergruppen erstellen Sie selbst im Abschnitt "BENUTZERGRUPPEN ERSTELLEN UND BEARBEITEN".\nSie können auch kontrollieren, was an jedem Standort gehört wird, von diesem Abschnitt aus.\nUm Playlists oder Werberichtlinien einem Benutzer zuzuweisen, müssen Sie zum entsprechenden Abschnitt gehen ("PLAYLISTS" oder "ANZEIGEN")',
  label46: "Angestellte Benutzer",
  label47: "Aktive Benutzer",
  label48: "Verfügbare Benutzer",
  label49: "Benutzer erstellen",
  label50: "Geben Sie die zu suchenden Benutzer oder Benutzergruppen ein...",
  label51: "Filtern nach...",
  label52: "Sortieren nach...",
  label53: "Name",
  label54: "E-Mail",
  label55: "Gruppe",
  label56: "Verbunden",
  label57: "Getrennt",
  label58: "Nie verbunden",
  label59: "Januar,Februar,März,April,Mai,Juni,Juli,August,September,Oktober,November,Dezember",
  label60: "Jan,Feb,Mär,Apr,Mai,Jun,Jul,Aug,Sep,Okt,Nov,Dez",
  label61: "Sonntag,Montag,Dienstag,Mittwoch,Donnerstag,Freitag,Samstag",
  label62: "So,Mo,Di,Mi,Do,Fr,Sa",
  label63: "So,Mo,Di,Mi,Do,Fr,Sa",
  label64: "Verbunden",
  label65: "Getrennt",
  label66: "Benutzer nie verbunden",
  label67: "Vom Dienstanbieter suspendiert",
  label68: "Aktiv",
  label69: "Wiedergabe der Playlist",
  label70: "Wiedergegebene Playlist",
  label71: "Von",
  label72: "Gruppe(n)",
  label73: "Ist keiner Gruppe zugeordnet",
  label74: "Benutzer erstellen",
  label75: "Benutzernamen müssen das @-Symbol enthalten. Wenn Sie es nicht einschließen, wird es automatisch am Anfang des Benutzernamens hinzugefügt.",
  label76: "Erforderlich",
  label77: "Passwort bestätigen",
  label78: "Optional",
  label79: "Beschreibung",
  label80: "Änderungen speichern",
  label81: "Änderungen werden gespeichert...",
  label82: "Erstellen",
  label83: "Wird erstellt...",
  label84: "Benutzernamen müssen das @-Symbol enthalten.\n Wenn Sie es nicht einschließen, wird es automatisch am Anfang des Benutzernamens hinzugefügt.",
  label85: "Ja",
  label86: "Nein",
  label87: "Informationen über",
  label88: "Benutzer",
  label89: "Derzeit keine Daten verfügbar",
  label90: "Benutzer erstellt",
  label91: "Sperr-PIN",
  label92: "Kein PIN zugewiesen",
  label93: "Letzte fünf (5) Verbindungen",
  label94: "Nicht angemeldet",
  label95: "Pausiert",
  label96: "Im Standby",
  label97: 'Sie können die Einstellungen eines Benutzers auf einen oder mehrere andere Benutzer oder auf eine oder mehrere Gruppen klonen. Wählen Sie den Ursprungsnutzer und dann den oder die Zielbenutzer oder die Zielgruppe aus. Wählen Sie die Einstellungen zum Klonen aus und klicken Sie auf "KLONEN".\n WICHTIG: Die geklonten Einstellungen überschreiben die vorhandenen Einstellungen der Zielbenutzer. Zum Beispiel werden beim Klonen der Playlists des Benutzers alle Playlists des Zielbenutzers oder der Zielgruppe gelöscht.',
  label98: "Ursprungsnutzer auswählen...",
  label99: "(Wählen Sie den Benutzer aus, von dem die unten ausgewählten Einstellungen geklont werden)",
  label100: "Benutzer auswählen",
  label101: "Gruppen auswählen",
  label102: "Alle Benutzer",
  label103: "Zielbenutzer oder -benutzer",
  label104: "Zielgruppe oder -gruppen",
  label105: "(Wählen Sie den oder die Benutzer aus, für die die unten ausgewählten Einstellungen geklont werden sollen)",
  label106: "(Wählen Sie die Zielgruppe oder -gruppen aus, für die die unten ausgewählten Einstellungen geklont werden sollen)",
  label107: "Zielbenutzer auswählen...",
  label108: "Zielgruppe oder -gruppen auswählen...",
  label109: "Zu klonende Einstellungen",
  label110: "Inhaltsadministrator",
  label111: "(Benutzerstile und Song-Änderungen)",
  label112: "Klonen",
  label113: "Klone...",
  label114: "Logo \n und Programmdaten anpassen",
  label115: 'Sie können die Anwendung mit Ihrem Logo und Ihren Daten anpassen. Das Logo wird beim Anmelden und im Menü angezeigt, und Ihre Daten werden im Abschnitt "Über diese Anwendung" angezeigt.',
  label116: "Bild auswählen",
  label117: "Optional (ersetzt das Anwendungslogo)",
  label118: "Lade ein quadratisches Bild im .jpg- oder .png-Format hoch.",
  label119: "Bild hochladen",
  label120: "Zurücksetzen",
  label121: "Daten",
  label122: '(Wird im Abschnitt "Über diese Anwendung" des Programms angezeigt)',
  label123: "Name des Unternehmens oder Geschäfts",
  label124: "Webseite",
  label125: "Text",
  label126: "Optional (z. B. Telefon oder Ansprechpartner)",
  label127: "Speichern",
  label128: "Speichern...",
  label129: "Löschen",
  label130: "Löschen...",
  label131: "Benutzerlogo",
  label132: "Die Anpassung wurde erfolgreich zurückgesetzt.",
  label133: "Fehler, etwas ist beim Zurücksetzen der Anpassung schief gelaufen.",
  label134: "Möchten Sie die Anpassung zurücksetzen?",
  label135: "Die Anpassung wird gelöscht und auf die Standardeinstellungen zurückgesetzt.",
  label136: 'Erstellen Sie Gruppen und weisen Sie dann Benutzer von der "LOKALE BENUTZER ERSTELLEN, KONFIGURIEREN UND STEUERN" Sektion den gewünschten Gruppen zu. Ein Benutzer kann mehreren Gruppen angehören. Benutzergruppen dienen dazu, die Verwaltung einer großen Anzahl von Benutzern zu erleichtern, indem Konfigurationen wie Playlist-Pläne, Werberichtlinien usw. zugewiesen werden.',
  label137: "Geben Sie den Gruppennamen zum Suchen ein...",
  label138: "Gruppe erstellen",
  label139: "Gruppe erstellen",
  label140: "Bearbeiten",
  label141: "Sie können Berichte über verwendete Playlists und veröffentlichte Anzeigen für die gewünschten Daten herunterladen.",
  label142: "Anzeigenberichte",
  label143: "Playlist-Berichte",
  label144: "(Es werden nur Berichte zu Werbeanzeigen angezeigt)",
  label145: "Berichtstyp",
  label146: "Berichtstyp auswählen...",
  label147: "Medium",
  label148: "Medium auswählen...",
  label149: "Allgemeiner Bericht (Detailliert)",
  label150: "Bericht nach Mediumauswahl (Zusammenfassung)",
  label151: "Bericht nach Mediumauswahl (Detailliert)",
  label152: "Startdatum",
  label153: "Erforderlich (von)",
  label154: "Enddatum",
  label155: "Erforderlich (bis)",
  label156: "(Es werden nur Berichte über die Verwendung von Playlists angezeigt)",
  label157: "Herunterladen",
  label158: "Herunterladen...",
  label159: "Fehler, beim Generieren des Berichts ist etwas schief gelaufen.",
  label160: "Der Bericht wurde erfolgreich generiert.",
  label161: "Fehler, Der Bericht wurde nicht generiert, es liegen keine Aufzeichnungen in diesem Zeitraum vor.",
  label162: "Bericht_von_",
  label163: "_(Zusammenfassung)",
  label164: "_(Detailliert)",
  label165: "Benutzername",
  label166: "Mediumname",
  label167: "Dateiname",
  label168: "Zeitplan von",
  label169: "Zeitplan bis",
  label170: "Wiedergabedatum",
  label171: "Wiedergabeanzahl",
  label172: "Bereich von",
  label173: "Bereich bis",
  label174: "Sperr-PIN zuweisen,\n Unangemessene Songs entfernen,\nStandby konfigurieren.",
  label175: "Benutzer auswählen...",
  label176: 'Sie können verschiedene Playlists oder Standbys für verschiedene Zeitslots an jedem Wochentag konfigurieren. Wählen Sie einen Benutzer aus und klicken Sie dann auf das "+"-Symbol ganz rechts in jedem Tag, um Ihre Konfigurationen vorzunehmen.',
  label177: "Fehler: Sie haben keine aktiven Konten. Wenden Sie sich an den Dienstanbieter.",
  label178: 'In diesem Abschnitt können Sie Anzeigen hochladen und Ihre bereits hochgeladenen Anzeigen löschen oder bearbeiten. Dann können Sie in "WERBERICHTLINIEN ERSTELLEN, BEARBEITEN UND KONFIGURIEREN" Ihre Werberichtlinien erstellen, um die Anzeigen in den Standorten abzuspielen.',
  label179: "Geben Sie den Namen der Anzeige zum Suchen ein...",
  label180: "Hochladen",
  label181: "Erstellen, bearbeiten \n und konfigurieren von Werberichtlinien",
  label182: "Sie können Werberichtlinien für Ihre eigenen Anzeigen (vorher hochgeladen) hinzufügen, um sie in den Standorten abzuspielen.",
  label183: "Richtlinie hinzufügen",
  label184: 'Erstellen Sie Gruppen und weisen Sie dann Benutzer von der "LOKALE BENUTZER ERSTELLEN, KONFIGURIEREN UND STEUERN" Sektion den gewünschten Gruppen zu. Ein Benutzer kann mehreren Gruppen angehören.',
  label185: "Medium hochladen",
  label186: "Werbetreibender",
  label187: "Marke oder Produkt",
  label188: "Benutzer (Lokal)",
  label189: "Benutzergruppe",
  label190: "Priorität",
  label191: "Anzahl der Veröffentlichungen",
  label192: "Mediumtyp",
  label193: "Bis",
  label194: "Standorte",
  label195: "Versionen",
  label196: "Unbegrenzt",
  label197: "Erstellen, bearbeiten \n und konfigurieren von Uhren",
  label198: "Sie können Werberichtlinien für Ihre eigenen Anzeigen (vorher hochgeladen) hinzufügen.",
  label199: "Uhren",
  label200: "Kalender",
  label201: "Uhren verwalten",
  label202: "Uhren für normalen Tag zuweisen",
  label203: "Uhren für besonderen Tag zuweisen",
  label204: "Kalender aktiviert",
  label205: "Kalender deaktiviert",
  label206: "Benutzer erfolgreich gelöscht.",
  label207: "Fehler, beim Löschen des Benutzers ist etwas schief gelaufen.",
  label208: "Möchten Sie den Benutzer löschen",
  label209: "Der Benutzer wird gelöscht",
  label210: "Fehler, der Benutzername enthält Leerzeichen (nicht erlaubt).",
  label211: "Fehler, der Benutzer muss 6-40 Zeichen lang sein.",
  label212: "Fehler, das Passwort muss 6-40 Zeichen lang sein.",
  label213: "Benutzer wurde erfolgreich erstellt.",
  label214: "Fehler, das Passwort und die Bestätigung stimmen nicht überein.",
  label215: "Fehler, das Passwort ist erforderlich.",
  label216: "Fehler, der Benutzername ist erforderlich.",
  label217: "Benutzer bearbeiten",
  label218: "Fehler, der Benutzername enthält das Zeichen # (nicht erlaubt).",
  label219: "Der Benutzer wurde erfolgreich bearbeitet.",
  label220: "Fehler, beim Bearbeiten des Benutzers ist etwas schief gelaufen.",
  label221: "Das Klonen wurde erfolgreich durchgeführt.",
  label222: "Fehler, beim Klonen ist etwas schief gelaufen.",
  label223: "Ursprungsnutzer",
  label224: "Fehler, beim Speichern der Gruppe ist etwas schief gelaufen.",
  label225: "Die Gruppe wurde erfolgreich gespeichert.",
  label226: "Fehler, beim Bearbeiten der Gruppe ist etwas schief gelaufen.",
  label227: "Die Gruppe wurde erfolgreich bearbeitet.",
  label228: "Die Gruppe",
  label229: "wurde erfolgreich gelöscht.",
  label230: "Fehler, beim Löschen der Gruppe ist etwas schief gelaufen",
  label231: "Möchten Sie die Gruppe löschen",
  label232: "Die Gruppe wird gelöscht",
  label233: "Die Anpassung wurde erfolgreich gespeichert.",
  label234: "Fehler, beim Speichern der Anpassung ist etwas schief gelaufen.",
  label235: "Favoriten anzeigen",
  label236: "Blendet die Favoritenleiste ein oder aus (untere Leiste)",
  label237: "Sperr-PIN hinzufügen",
  label238: "Sperr-PIN ändern",
  label239: "Sie haben keinen Sperr-PIN",
  label240: "Ihr Sperr-PIN lautet",
  label241: "PIN hinzufügen",
  label242: "PIN ändern",
  label243: "Jetzt sperren",
  label244: "Um die Anwendung jetzt zu sperren, ist eine PIN erforderlich",
  label245: "Anwendung jetzt sperren",
  label246: "Anwendung beim Start sperren",
  label247: "Um die Anwendung immer gesperrt zu starten, ist eine PIN erforderlich",
  label248: "Die Anwendung wird immer gesperrt gestartet",
  label249: "Sprache ändern",
  label250: "Ändern Sie die Sprache der Anwendung",
  label251: "Schaltflächen im Abspielmodus anzeigen",
  label252: "Schaltflächen anzeigen oder ausblenden (nicht planen, immer planen, bearbeiten, hinzufügen)",
  label253: "Einschränkung für explizite Inhalte aktivieren",
  label254: "Die Planung von Songs mit unangemessenem oder missbräuchlichem Inhalt ist eingeschränkt",
  label255: "Ruhemodus",
  label256: "Ruhemodus-Einstellungen bearbeiten",
  label257: "Ruhemodus-Einstellung hinzufügen",
  label258: "Hinzufügen",
  label259: "Die Anwendungssprache wurde erfolgreich auf Englisch geändert.",
  label260: "Fehler, beim Ändern der Anwendungssprache auf Englisch ist etwas schief gelaufen.",
  label261: "Die Anwendungssprache wurde erfolgreich auf Spanisch geändert.",
  label262: "Fehler, beim Ändern der Anwendungssprache auf Spanisch ist etwas schief gelaufen.",
  label263: "Die Anwendungssprache wurde erfolgreich auf Französisch geändert.",
  label264: "Fehler, beim Ändern der Anwendungssprache auf Französisch ist etwas schief gelaufen.",
  label265: "Favoritenleiste wurde erfolgreich aktiviert.",
  label266: "Favoritenleiste wurde erfolgreich deaktiviert.",
  label267: "Fehler, beim Aktivieren der Favoritenleiste ist etwas schief gelaufen.",
  label268: "Fehler, beim Deaktivieren der Favoritenleiste ist etwas schief gelaufen.",
  label269: "Die Anwendung wurde erfolgreich gesperrt.",
  label270: "Die Anwendung wurde erfolgreich entsperrt.",
  label271: "Fehler, beim Sperren der Anwendung ist etwas schief gelaufen.",
  label272: "Fehler, beim Entsperren der Anwendung ist etwas schief gelaufen.",
  label273: "Startsperre wurde erfolgreich aktiviert.",
  label274: "Startsperre wurde erfolgreich deaktiviert.",
  label275: "Fehler, beim Aktivieren der Startsperre ist etwas schief gelaufen.",
  label276: "Fehler, beim Deaktivieren der Startsperre ist etwas schief gelaufen.",
  label277: "Wiedergabeschaltflächen wurden aktiviert.",
  label278: "Wiedergabeschaltflächen wurden deaktiviert.",
  label279: "Fehler, beim Aktivieren der Wiedergabeschaltflächen ist etwas schief gelaufen.",
  label280: "Fehler, beim Deaktivieren der Wiedergabeschaltflächen ist etwas schief gelaufen.",
  label281: "Einschränkung für explizite Inhalte wurde aktiviert.",
  label282: "Einschränkung für explizite Inhalte wurde deaktiviert.",
  label283: "Fehler, beim Aktivieren der Einschränkung für explizite Inhalte ist etwas schief gelaufen.",
  label284: "Fehler, beim Deaktivieren der Einschränkung für explizite Inhalte ist etwas schief gelaufen.",
  label285: "Ruhemodus wurde aktiviert.",
  label286: "Ruhemodus wurde deaktiviert.",
  label287: "Fehler, beim Aktivieren des Ruhemodus ist etwas schief gelaufen.",
  label288: "Fehler, beim Deaktivieren des Ruhemodus ist etwas schief gelaufen.",
  label289: "Hinzufügen \n PIN",
  label290: "Bearbeiten \n PIN",
  label291: "Aktuelle PIN",
  label292: "Neue PIN",
  label293: "Erforderlich (PIN muss 4-stellig sein)",
  label294: "Bestätigen Sie Ihre neue PIN",
  label295: "Fehler, in Ihrer PIN sind nur Zahlen erlaubt und 0000 ist nicht erlaubt",
  label296: "Fehler, die PIN muss 4-stellig sein.",
  label297: "Fehler, die Bestätigung stimmt nicht mit der neuen PIN überein.",
  label298: "Fehler, die PIN ist identisch mit der vorherigen. Bitte geben Sie eine neue PIN ein.",
  label299: "Die PIN wurde erfolgreich gespeichert.",
  label300: "Die PIN wurde erfolgreich geändert.",
  label301: "Fehler, beim Speichern der PIN ist etwas schief gelaufen.",
  label302: "Fehler, beim Ändern der PIN ist etwas schief gelaufen.",
  label303: "Eine Ruhemodus-Einstellung \n hinzufügen",
  label304: "Eine Ruhemodus-Einstellung \n bearbeiten",
  label305: "Status",
  label306: "Ruhemodus-Zeitplan",
  label307: "An",
  label308: "Jeden Tag",
  label309: "Angegebene Tage",
  label310: "Die Ruhemodus-Einstellung wurde erfolgreich gespeichert.",
  label311: "Fehler, beim Speichern der Ruhemodus-Einstellung ist etwas schief gelaufen.",
  label312: "Die Ruhemodus-Einstellung wurde erfolgreich bearbeitet.",
  label313: "Fehler, beim Bearbeiten der Ruhemodus-Einstellung ist etwas schief gelaufen.",
  label314: "Planer erfolgreich aktiviert.",
  label315: "Planer erfolgreich deaktiviert.",
  label316: "Fehler, beim Aktivieren des Planers ist etwas schief gelaufen.",
  label317: "Fehler, beim Deaktivieren des Planers ist etwas schief gelaufen.",
  label318: "Planer aktiviert",
  label319: "Planer deaktiviert",
  label320: "Plan hinzufügen",
  label323: "Meine Playlists",
  label324: "Zufällig",
  label325: "Der Plan wurde erfolgreich gespeichert.",
  label326: "Fehler, beim Speichern des Plans ist etwas schief gelaufen.",
  label327: 'Fehler, die Uhrzeit "Von" ist kleiner als die Uhrzeit "Bis".',
  label328: "Fehler, es existiert bereits ein Plan in diesem Zeitraum.",
  label329: "Anzeige hochladen",
  label330: "Der Plan wurde erfolgreich gelöscht.",
  label331: "Fehler, beim Löschen des Plans ist etwas schief gelaufen.",
  label332: "Zufällig aktiviert.",
  label333: "Zufällig deaktiviert.",
  label334: "Fehler, beim Aktivieren der Zufallswiedergabe ist etwas schief gelaufen.",
  label335: "Fehler, beim Deaktivieren der Zufallswiedergabe ist etwas schief gelaufen.",
  label336: "Ziehe \n deine Anzeige \n hierhin \n oder klicke \n in diesem Bereich",
  label337: "Größe",
  label338: "(Nur mp3. Maximale Größe 5 MB).",
  label339: "Anzeige hochladen",
  label340: "Hochladen der Anzeige...",
  label341: "Fehler, (NUR MP3).",
  label342: "Der Anzeigenname muss zwischen 1 und 50 Zeichen lang sein.",
  label343: "Fehler, beim Hochladen der Anzeige ist etwas schief gelaufen. Überprüfen Sie, ob es sich um eine MP3-Datei handelt und ob die Größe nicht größer als 5 MB ist.",
  label344: "Die Anzeige wurde erfolgreich hochgeladen.",
  label345: "Die Anzeige",
  label346: "Fehler, etwas ist beim Löschen der Anzeige schief gelaufen.",
  label347: "Möchten Sie die Anzeige löschen",
  label348: "Die Anzeige wird gelöscht",
  label349: "Jeden Tag",
  label350: "Montag bis Freitag",
  label351: "Samstag und Sonntag",
  label352: "Genauer Tag",
  label353: "Genaue Uhrzeit",
  label354: "Intervall",
  label355: "Alle",
  label356: "Frequenz",
  label357: "Lieder",
  label358: "Anzeigenlimit pro Runde",
  label359: "Start der Pausenmusik",
  label360: "Ende der Pausenmusik",
  label361: "Pausenmusik hinzufügen",
  label362: "Tage",
  label363: "Zeitplan",
  label364: "Zeitrahmen",
  label365: "Anzeigenlimit pro Runde",
  label366: "Alle x Minuten",
  label367: "Alle x Lieder",
  label368: "Tag oder Tage",
  label369: "Stunde",
  label370: "Tag",
  label371: "Start festlegen",
  label372: "Ende festlegen",
  label373: "Fehler, es gibt bereits eine Pausenmusik mit diesem Namen.",
  label374: "Fehler, es gibt bereits eine Pausenmusik in diesem Zeitintervall.",
  label375: "Fehler, es gibt bereits eine Pausenmusik zu dieser genauen Uhrzeit.",
  label376: "Das Medium",
  label377: "Fehler, etwas ist beim Löschen des Mediums schief gelaufen.",
  label378: "Möchten Sie das Medium löschen",
  label379: "Das Medium wird gelöscht",
  label380: "bis",
  label381: "Hoch",
  label382: "Normal",
  label383: "Sie können jede Art von Medium hochladen (Spot, Anzeige, Zwischensong, Radioidentifikation) \n oder Ihren eigenen Typ erstellen. Wählen Sie die hochzuladende Datei aus, geben Sie ihr einen Namen, geben Sie an, um welche Art von Medium es sich handelt, \n weisen Sie alle Werte zu, falls es Versionen gibt, können Sie diese hochladen, und geben Sie die Orte an, an denen sie gespielt werden sollen.",
  label384: "Start und Ende festlegen",
  label385: "Auswählen…",
  label386: "Start",
  label387: "Ende",
  label388: "Marke oder Produkt",
  label389: "Werbendes Unternehmen",
  label390: "Werte",
  label391: "Unendlich",
  label392: "Orte, an denen \n es veröffentlicht wird",
  label393: "Alle Benutzer (Standorte)",
  label394: "Benutzer (Standorte) oder Gruppen auswählen",
  label395: "Benutzer (Standorte)",
  label396: "Gruppen",
  label397: "Fehler, etwas ist beim Laden des Mediums schief gelaufen.",
  label398: "Das Medium wurde erfolgreich gespeichert.",
  label399: "Fehler, Sie müssen eine Priorität auswählen.",
  label400: "Fehler, Sie haben keinen Standort für die Veröffentlichung ausgewählt.",
  label401: "Fehler, Sie müssen eine Marke oder ein Produkt auswählen.",
  label402: "Fehler, Sie müssen einen Werbenden auswählen.",
  label403: "Fehler, Sie müssen einen Medientyp auswählen.",
  label404: "Fehler, Sie müssen einen Medienname hinzufügen.",
  label405: "Nach",
  label406: "Ziehen Sie hier \n Benutzer oder Gruppen hin",
  label407: "Fehler, Beim Bearbeiten des Mediums ist etwas schief gelaufen.",
  label408: "Das Medium wurde erfolgreich bearbeitet.",
  label409: "Medientyp hinzufügen",
  label410: "Fehler, Beim Speichern des Medientyps ist etwas schief gelaufen.",
  label411: "Der Medientyp wurde erfolgreich gespeichert.",
  label412: "Fehler, der Name des Medientyps muss 1 bis 50 Zeichen enthalten.",
  label413: "Werbenden hinzufügen",
  label414: "Fehler, Beim Speichern des Werbenden ist etwas schief gelaufen.",
  label415: "Der Werbende wurde erfolgreich gespeichert.",
  label416: "Fehler, der Name des Werbenden muss 1 bis 50 Zeichen enthalten.",
  label417: "Marke oder Produkt hinzufügen",
  label418: "Hier eingeben, um zu suchen...",
  label419: "Fehler, Beim Speichern der Marke oder des Produkts ist etwas schief gelaufen.",
  label420: "Die Marke oder das Produkt wurde erfolgreich gespeichert.",
  label421: "Fehler, der Name der Marke oder des Produkts muss 1 bis 50 Zeichen enthalten.",
  label422: "Versionen laden zu",
  label423: "Die Version",
  label424: "Fehler, beim Löschen der Version ist etwas schief gelaufen.",
  label425: "Möchten Sie die Version löschen",
  label426: "Die Version wird gelöscht",
  label427: "Abbrechen",
  label428: "Fehler, beim Laden der Version ist etwas schief gelaufen.",
  label429: "Die Version wurde erfolgreich hochgeladen.",
  label430: "Fehler. Die Version konnte nicht hochgeladen werden. Schließen Sie das Fenster und versuchen Sie es erneut.",
  label431: "Kalender erfolgreich aktiviert.",
  label432: "Kalender erfolgreich deaktiviert.",
  label433: "Fehler, beim Aktivieren des Kalenders ist etwas schief gelaufen.",
  label434: "Fehler, beim Deaktivieren des Kalenders ist etwas schief gelaufen.",
  label435: "Uhr erstellen",
  label436: "Um eine Uhr zu erstellen, ziehen Sie die Medien, die links angezeigt werden, nach rechts. Anschließend können Sie sie nach oben oder unten ziehen, um sie zu ordnen. Der Medientyp \"WERBUNG\" hat standardmäßig 16 Werbespots. Die anderen haben standardmäßig nur 1 Medium. In beiden Fällen kann diese Zahl durch Klicken auf den Stift bearbeitet werden.",
  label437: "Mediensuche",
  label438: "Ziehe die \n Medien hierhin",
  label439: "Fehler, der Name der Uhr muss 1 bis 50 Zeichen enthalten.",
  label440: "Fehler, beim Speichern der Uhr ist etwas schief gelaufen.",
  label441: "Die Uhr wurde erfolgreich gespeichert.",
  label442: "Fehler, Sie haben kein Medium hinzugefügt.",
  label443: "Uhren verwalten",
  label444: "Bearbeiten oder löschen Sie die Uhr. Wenn Sie die Uhr löschen, verschwindet sie aus dem Kalender.",
  label445: "Die Uhr",
  label446: "Fehler, beim Löschen der Uhr ist etwas schief gelaufen.",
  label447: "Möchten Sie die Uhr löschen",
  label448: "Die Uhr wird gelöscht",
  label449: "Menge",
  label450: "Blöcke von",
  label451: `Fehler, beim Bearbeiten ist etwas schief gelaufen`,
  label452: `Um eine Uhr zu bearbeiten, ziehen Sie die Medien, die links angezeigt werden, nach rechts. Anschließend können Sie sie nach oben oder unten ziehen, um sie zu ordnen. Der Medientyp "WERBUNG" hat standardmäßig 16 Werbespots. Die anderen haben standardmäßig nur 1 Medium. In beiden Fällen kann diese Zahl durch Klicken auf den Stift bearbeitet werden.`,
  label453: `Die Uhr wurde erfolgreich bearbeitet.`,
  label454: `Weisen Sie jeder Zeitzone eine Uhr zu. Sie können Zeiträume ohne zugewiesene Uhr lassen; in diesem Fall wird nur Musik (ohne Medien) abgespielt.`,
  label455: `Keine zugewiesene Uhr`,
  label456: `Fehler, beim Speichern der Uhr an einem normalen Tag ist etwas schief gelaufen.`,
  label457: `Die Uhr an einem normalen Tag wurde erfolgreich gespeichert.`,
  label458: `Uhren an einem besonderen Tag zuweisen`,
  label459: `Wiederholungen`,
  label460: `Bestimmter Tag`,
  label461: `Zeitraum der Tage`,
  label462: `Fehler, der Name des besonderen Tages muss 1 bis 50 Zeichen enthalten.`,
  label463: `Die Uhr an einem besonderen Tag wurde erfolgreich gespeichert.`,
  label464: `Fehler, beim Speichern der Uhr an einem besonderen Tag ist etwas schief gelaufen.`,
  label465: `Fehler, es gibt bereits eine Uhr für diesen bestimmten Tag und die Wiederholung fällt zusammen oder liegt innerhalb eines anderen Zeitraums.`,
  label466: `Fehler, es gibt bereits eine Uhr für diesen Zeitraum.`,
  label467: `Fehler, das Enddatum ist größer als das Startdatum.`,
  label468: `Bearbeiten oder Löschen \n Tagesstruktur`,
  label469: `Bearbeiten oder Löschen, bei Änderung eines Werts in den Auswahlfeldern wird dies automatisch bearbeitet. Wenn Sie den Tag löschen, wird er aus dem Kalender entfernt.`,
  label470: `Dieses Intervall in der Tagesstruktur wurde erfolgreich aktualisiert.`,
  label471: `Fehler. Etwas ist schief gelaufen beim Bearbeiten dieses Intervalls.`,
  label472: `Letzte Verbindung`,
  label473: `Verbunden am `,
  label474: `Beenden`,
  label475: `Medien verwalten`,
  label476: `Werbende Unternehmen verwalten`,
  label477: `Marken oder Produkte verwalten`,
  label478: `Bearbeiten oder Löschen, Sie können keinen Medientyp löschen, der bereits einem geladenen Medium zugeordnet ist.`,
  label479: `Bearbeiten oder Löschen, Sie können kein werbendes Unternehmen löschen, das bereits einem geladenen Medium zugeordnet ist.`,
  label480: `Bearbeiten oder Löschen, Sie können keine Marke oder Produkt löschen, die bereits einem geladenen Medium zugeordnet ist.`,
  label481: `Medientyp`,
  label482: `Werbendes Unternehmen`,
  label483: `Marke oder Produkt`,
  label484: `Fehler, beim Löschen des Medientyps ist etwas schief gelaufen`,
  label485: `Fehler, beim Löschen des werbenden Unternehmens ist etwas schief gelaufen`,
  label486: `Fehler, beim Löschen der Marke oder des Produkts ist etwas schief gelaufen`,
  label487: `Möchten Sie den Medientyp löschen`,
  label488: `Möchten Sie das werbende Unternehmen löschen`,
  label489: `Möchten Sie die Marke oder das Produkt löschen`,
  label490: `Der Medientyp wird gelöscht`,
  label491: `Das werbende Unternehmen wird gelöscht`,
  label492: `Die Marke oder das Produkt wird gelöscht`,
  label493: `OK`,
  label494: `Hinweis`,
  label495: `Dieser Medientyp kann nicht gelöscht werden, da er einem oder mehreren bereits geladenen Medien zugeordnet ist. Wenn Sie ihn löschen möchten, müssen Sie zuerst die damit verbundenen Medien löschen.`,
  label496: `Dieses werbende Unternehmen kann nicht gelöscht werden, da es bereits einem oder mehreren geladenen Medien zugeordnet ist. Wenn Sie es löschen möchten, müssen Sie zuerst die damit verbundenen Medien löschen.`,
  label497: `Diese Marke oder dieses Produkt kann nicht gelöscht werden, da es bereits einem oder mehreren geladenen Medien zugeordnet ist. Wenn Sie es löschen möchten, müssen Sie zuerst die damit verbundenen Medien löschen.`,
  label498: `Sie haben das Limit der für Ihr Abonnement verfügbaren zu ladenden Medien überschritten. Kontaktieren Sie uns, um Ihr Abonnementlimit zu erweitern.`,
  label499: `Sie haben das Limit der für Ihr Abonnement verfügbaren Versionen pro Medium überschritten. Kontaktieren Sie uns, um Ihr Abonnementlimit zu erweitern.`,
  label500: `Sie haben das Limit der für Ihr Abonnement verfügbaren Anzeigengröße überschritten. Kontaktieren Sie uns, um Ihr Abonnementlimit zu erweitern.`,
  label501: `Sie haben das Limit für die Anzahl der erstellbaren Medientypen überschritten. Kontaktieren Sie uns, um Ihr Abonnementlimit zu erweitern.`,
  label502: `Ihr aktuelles Limit für die Erstellung von Medientypen beträgt: `,
  label503: `Ihr aktuelles Limit für das Hochladen von Medien beträgt: `,
  label504: `Ihr aktuelles Limit für das Hochladen von Versionen beträgt: `,
  label505: `Ihr aktuelles Limit für die Anzeigengröße pro Anzeige beträgt: `,
  label506: `Möchten Sie die Pauta löschen`,
  label507: `Die Pauta wird gelöscht`,
  label508: `Hinzufügen / Entfernen`,
  label509: `Anzahl der geladenen Anzeigen`,
  label510: `Anzeigen hinzufügen oder entfernen`,
  label511: `Anzeigen hinzufügen oder entfernen, Sie müssen die Anzeigen, die links angezeigt werden, nach rechts ziehen. Anschließend können Sie sie nach oben oder unten ziehen, um sie zu ordnen.`,
  label512: `Anzeigen für die Pauta`,
  label513: `Suche nach Anzeigen`,
  label514: `Ziehen Sie die \n Anzeigen hierher`,
  label515: `Fehler, beim Speichern der Pauta ist etwas schief gelaufen.`,
  label516: `Die Pauta wurde erfolgreich gespeichert.`,
  label517: `Fehler, beim Bearbeiten der Pauta ist etwas schief gelaufen.`,
  label518: `Die Pauta wurde erfolgreich bearbeitet.`,
  label519: `Bericht nach Auswahl des bereits gelöschten Mediums (Zusammenfassung)`,
  label520: `Bericht nach Auswahl des bereits gelöschten Mediums (Detailansicht)`,
  label521: `Bereits gelöschtes Medium`,
  label522: `Meine Stile`,
  label523: `Sie können Ihre eigenen Musikstile erstellen und dann zu "SONGS VERWALTEN" gehen, um ihnen die gewünschten Songs zuzuweisen.`,
  label524: `Geben Sie den Namen des zu suchenden Stils ein...`,
  label525: `Der Stil`,
  label526: `Fehler, beim Löschen des Stils ist etwas schief gelaufen`,
  label527: `Möchten Sie den Stil löschen`,
  label528: `Der Stil wird gelöscht`,
  label529: `Stil erstellen`,
  label530: `Stil erstellen`,
  label531: `Fehler, beim Speichern des Stils ist etwas schief gelaufen.`,
  label532: `Der Stil wurde erfolgreich gespeichert.`,
  label533: `Fehler, der Name des Stils muss 1 bis 50 Zeichen enthalten.`,
  label534: `Fehler, beim Bearbeiten des Stils ist etwas schief gelaufen.`,
  label535: `Der Stil wurde erfolgreich bearbeitet.`,
  label536: `Stil bearbeiten`,
  label537: `Song-Manager`,
  label538: `Sie können den Stil eines Songs in einen vom Benutzer erstellten Stil ändern. Sie können den Song umbenennen, ihn als explizit (unangemessene Sprache) kennzeichnen oder ihn als Favorit oder unerwünscht markieren. Sie können ihn auch einer manuellen Playlist zuweisen.`,
  label539: `Geben Sie hier den Namen des Songs oder Interpreten ein, um nach ihm zu suchen...`,
  label540: `Suchen`,
  label541: `Anzeigen`,
  label542: `Alle`,
  label543: `Nur bearbeitet`,
  label544: `Favoriten`,
  label545: `Unerwünschte`,
  label546: `Song bearbeiten`,
  label547: `Standard`,
  label548: `Fehler, die Felder für Name und Passwort sind erforderlich.`,
  label549: `Originaltitel`,
  label550: `Musikstil`,
  label551: `Humor`,  
  label552: `Vorliebe`,  
  label553: `Expliziter Song`,  
  label554: `(unangemessener oder missbräuchlicher Inhalt)`,  
  label555: `Fehler, etwas ist beim Bearbeiten des Songs schief gelaufen.`,  
  label556: `Der Song wurde erfolgreich bearbeitet.`,  
  label557: `Berechtigungen zur Nutzung der Musik-App, klicken Sie auf die Schaltfläche „Speichern“, um die Änderungen zu übernehmen.`,  
  label558: `Möchten Sie diesen Song als Favorit abwählen`,  
  label559: `Möchten Sie diesen Song als Favorit markieren`,  
  label560: `wird nicht mehr immer nur normal abgespielt.`,  
  label561: `wird immer in den Playlists abgespielt, die dem Stil dieses Songs zugeordnet sind.`,  
  label562: `Fehler, etwas ist beim Abwählen dieses Songs als Favorit schief gelaufen.`,  
  label563: `Fehler, etwas ist beim Markieren dieses Songs als Favorit schief gelaufen.`,  
  label564: `Der Song wurde erfolgreich als Favorit abgewählt.`,  
  label565: `Der Song wurde erfolgreich als Favorit markiert.`,  
  label566: `Möchten Sie diesen Song als Nicht mögen abwählen`,  
  label567: `Möchten Sie diesen Song als Nicht mögen markieren`,  
  label568: `wird wieder normal abgespielt.`,  
  label569: `wird nicht mehr abgespielt.`,  
  label570: `Fehler, etwas ist beim Abwählen dieses Songs als Nicht mögen schief gelaufen.`,  
  label571: `Fehler, etwas ist beim Markieren dieses Songs als Nicht mögen schief gelaufen.`,  
  label572: `Der Song wurde erfolgreich als Nicht mögen abgewählt.`,  
  label573: `Der Song wurde erfolgreich als Nicht mögen markiert.`,  
  label574: `Playlist auswählen...`,  
  label575: `Land`,  
  label576: `Stadt`,  
  label577: `Provinz`,  
  label578: `Fehler, der Name wird bereits verwendet, bitte wählen Sie einen anderen Namen.`,  
  label579: `Sie können Ihre eigenen Playlists auf zwei verschiedene Arten erstellen. Automatisch: Sie wählen die Stile aus, und das Programm wählt die Songs aus. Manuell: Sie wählen die Songs einzeln aus. Sie können auch Playlists löschen oder vorhandene bearbeiten.`,  
  label580: `Die Playlist`,  
  label581: `Fehler, beim Löschen der Playlist ist etwas schief gelaufen.`,  
  label582: `Möchten Sie die Playlist löschen`,  
  label583: `Die Playlist wird gelöscht`,  
  label584: `Manuelle Playlist`,  
  label585: `Automatische Playlist`,  
  label586: `Geben Sie hier den Namen der zu suchenden Playlist ein...`,  
  label587: `Playlist erstellen`,  
  label588: `Werbung des Plans`,  
  label589: `Playlist erstellen`,  
  label590: `Wählen Sie eine Option.`,  
  label591: `Automatisch`,  
  label592: `Manuell`,  
  label593: `Sie wählen die Stile aus, und das Programm wählt die Songs aus.`,  
  label594: `Sie wählen die Songs einzeln aus.`,  
  label595: `Wählen Sie den Typ der zu erstellenden Playlist aus...`,  
  label596: `Name der neuen Playlist (obligatorisch)...`,  
  label597: `Beschreibung (optional)...`,  
  label598: `Weiter`,  
  label599: `Ziehen Sie die Stile, die Sie möchten, von links nach rechts. Sie können einen Stil mehrmals ziehen, um mehr Songs dieses Stils zu planen. Dann können Sie im rechten Panel die Stile nach oben und unten ziehen, um die Reihenfolge festzulegen. Sie können auch die Einstellungen des Stils ändern, um ruhigere oder lebhaftere Songs zu planen, usw.`,  
  label600: `Geben Sie hier den Namen des zu suchenden Stils ein...`,
  label601: `Musikgenre-Suchmaschine`,  
  label602: `Genres`,  
  label603: `Meine Genres`,  
  label604: `Dauer (in Stunden)`,  
  label605: `Explizite einschränken`,  
  label606: `Ziehe die Genres hierher`,  
  label607: `Fehler, beim Erstellen der automatischen Playlist ist etwas schief gelaufen.`,  
  label608: `Die automatische Playlist wurde erfolgreich erstellt.`,  
  label609: `Fehler, es wurde kein Genre ausgewählt, um die Playlist zu erstellen.`,  
  label610: `Bearbeiten \n automatische Playlists`,  
  label611: `Ziehe die Genres von links nach rechts. Du kannst ein Genre mehrmals ziehen, um mehr Songs dieses Genres zu planen. Dann kannst du im rechten Panel die Genres nach oben und unten ziehen, um die Reihenfolge festzulegen. Du kannst auch die Einstellungen des Genres ändern, um ruhigere oder lebhaftere Songs zu planen, usw.`,  
  label612: `Fehler, beim Bearbeiten der automatischen Playlist ist etwas schief gelaufen.`,  
  label613: `Die automatische Playlist wurde erfolgreich bearbeitet.`,  
  label614: `Fehler, der Name der Playlist muss mindestens ein Zeichen enthalten.`,  
  label615: `Ändern`,  
  label616: `Nur Hits`,  
  label617: `Hits und Empfehlungen`,  
  label618: `Hits, Empfehlungen und Verschiedenes`,  
  label619: `Suche die Songs, die du möchtest, nach ihrem Namen oder dem Namen des Interpreten. Ziehe dann \n jeden Song von links nach rechts. Im rechten Panel kannst du die Songs nach oben und unten ziehen, um sie zu ordnen.`,  
  label620: `Song-Suchmaschine`,  
  label621: `Ziehe die Songs hierher`,  
  label622: `Fehler, beim Erstellen der manuellen Playlist ist etwas schief gelaufen.`,  
  label623: `Die manuelle Playlist wurde erfolgreich erstellt.`,  
  label624: `Fehler, die maximale Anzahl von Songs pro Playlist beträgt`,  
  label625: `Bearbeiten \n manuelle Playlist`,  
  label626: `Fehler, beim Bearbeiten der manuellen Playlist ist etwas schief gelaufen.`,  
  label627: `Die manuelle Playlist wurde erfolgreich bearbeitet.`,  
  label629: `Anzahl der Songs`,  
  label630: `(Administrator) - `,  
  label631: `(Benutzer) - `,  
  label632: `zu manuellen Playlists`,  
  label633: `Es können nur Songs zu manuellen Playlists hinzugefügt werden, die von dir erstellt wurden.`,  
  label634: `Ziel-Playlists`,  
  label635: `Hinzufügen...`,  
  label636: `Der Song wurde erfolgreich hinzugefügt.`,  
  label637: `Fehler, beim Hinzufügen des Songs ist etwas schief gelaufen.`,  
  label638: `Gruppe auswählen...`,  
  label639: `Verbindungstyp`,  
  label640: `Verbindungstyp auswählen...`,  
  label641: `Einzelne Suche`,  
  label642: `Ergebnisse: `,  
  label643: `Filter anwenden`,  
  label644: `Gib hier den Benutzernamen ein, den du suchen möchtest...`,  
  label645: `Nicht explizite Songs`,  
  label646: `Explizite Songs`,  
  label647: `Ein Genre auswählen...`,  
  label648: `Ein Genre des Benutzers auswählen...`,  
  label649: `Eine Stimmung auswählen...`,  
  label650: `Expliziter Inhalt`,  
  label651: `Den Inhaltstyp auswählen...`,  
  label652: `Benutzerkriterium`,  
  label653: `Benutzergenres`,  
  label654: `Fehler bei der Audiodatei.`,  
  label655: `Aktuelles Passwort`,  
  label656: `Land`,  
  label657: `Provinz`,  
  label658: `Stadt`,  
  label659: `Hibernation`,  
  label660: `Fehler, die Startzeit muss größer als die Endzeit sein.`,
  label661: `Fehler, der Zeitunterschied muss mindestens 5 Minuten betragen.`,
  label662: `Fehler, Sie müssen mindestens einen Tag auswählen.`,
  label663: `Fehler, es gibt (`,
  label664: `) Plan(e), die sich in dem von Ihnen gewählten Zeitraum auf den ausgewählten Tag(en) auswirken.`,
  label665: `Alle Pläne für den Tag wurden gelöscht, `,
  label666: `Fehler, beim Löschen aller Pläne für den Tag ist etwas schief gelaufen, `,
  label667: `Möchten Sie alle Pläne für den Tag löschen?`,
  label668: `Alle Pläne für diesen Tag werden gelöscht, `,
  label669: `Gesamtlautstärke der App`,
  label670: `Die App-Lautstärke reicht von 0 bis 100`,
  label671: `Die App-Lautstärke wurde erfolgreich aktualisiert.`,
  label672: `Fehler beim Aktualisieren der App-Lautstärke.`,
  label673: `Die App stummschalten`,
  label674: `Die App nicht mehr stummschalten`,
  label675: `App-Sound deaktivieren`,
  label676: `App-Sound aktivieren`,
  label677: "Die App wurde erfolgreich stummgeschaltet.",
  label678: "Die App wurde erfolgreich entstummt.",
  label679: "Fehler, beim Stummschalten der App ist etwas schief gelaufen.",
  label680: "Fehler, beim Entstummen der App ist etwas schief gelaufen.",
  label681: "Alles zurücksetzen",
  label682: "Die Benutzeranpassungen wurden erfolgreich zurückgesetzt.",
  label683: "Fehler, beim Zurücksetzen der Benutzeranpassungen ist etwas schief gelaufen.",
  label684: "Möchten Sie die Benutzeranpassungen zurücksetzen?",
  label685: "Die Benutzeranpassungen werden gelöscht und für jeden Benutzer auf die Standardeinstellungen zurückgesetzt.",
  label686: "Ohne Beschreibung",
  root: {
    1: "GOTT",
    2: "SUPER-ROOT",
    3: "ROOT",
    4: "SUPER-ADMINISTRATOR",
    5: "ADMINISTRATOR",
    6: "ÜBERWACHER",
    7: "BENUTZER-ADMINISTRATOR",
    8: "EINZELNER BENUTZER",
  },
  label687: "Konto",
  label688: "Kontoverwaltung",
  label689: "Passwort ändern",
  label690: "Kontodaten ändern (Firmenname, Adresse, CIF/NIF, etc.)",
  label691: "Rechnungen herunterladen oder Kreditkarteninformationen aktualisieren",
  label692: "Abonnement ändern oder kündigen",
  label693: "Änderung des Passworts für das aktuelle Konto.",
  label694: "Neues Passwort",
  label695: "Das Passwort wurde erfolgreich geändert.",
  label696: "Fehler, etwas ist beim Ändern des Passworts in amember schief gelaufen.",
  label697: "Fehler, etwas ist beim Ändern des Passworts schief gelaufen.",
  label698: "Installation mit Administratorrechten. Unter Windows 10 geben Sie einfach den Benutzernamen und das Passwort des Administrators ein, wenn Sie das Programm installieren. Unter Windows 7 muss das Konto, in dem die Installation durchgeführt wird, Administratorrechte haben. Wenn Sie das Programm in einem Standardkonto (kein Administrator) installieren möchten, müssen Sie dieses Konto in ein Administratorkonto umwandeln, das Programm installieren und es dann wieder in ein Standardkonto umwandeln. In jedem Fall sollten Sie überprüfen, ob kein Programm den Zugriff der App auf das Internet blockiert (Antivirus, Firewall, etc.).",
  label699: 'Um das Programm auf einem Mac OS-Computer zu installieren, gehen Sie wie folgt vor: Gehen Sie zu Systemeinstellungen Sicherheit und Datenschutz. Klicken Sie auf das Schloss unten links und geben Sie Ihr Benutzerpasswort ein, um Ihren Mac zu entsperren. Markieren Sie das Kästchen "Jede Website" im Bereich "Apps von Drittanbietern erlauben".',
  label700: "Wir empfehlen, die App über den Google Play Store zu installieren und automatische Updates zu aktivieren. Bei Installation außerhalb des Stores müssen Sie die Aktualisierungen der App selbst verwalten, indem Sie die neueste auf dieser Seite veröffentlichte Version überprüfen.",
  label701: "Downloads",
  label702: "Wichtig",
  label703: "Dieser Bereich ist öffentlich, es wird kein Passwort benötigt, um hierher zu gelangen. Kopieren Sie den Link zu dieser Seite unten und teilen Sie ihn mit allen Ihren Benutzern, die das Programm herunterladen müssen.",
  label704: "Letzte Version der App",
  label705: "Gehe zu",
  label706: "Anleitungen",
  label707: "Technische Anforderungen",
  label708: 'Betriebssystem Windows 7 oder höher (Windows 10 empfohlen), in der N-Version von Windows kann die Anwendung nicht verwendet werden, da sie kein "Media Feature Pack" hat, siehe Supportbereich in diesem Fall.',
  label709: "16 GB verfügbarer Festplattenspeicher (32 GB empfohlen oder mehr)",
  label710: "4 GB RAM oder mehr",
  label711: "Soundkarte",
  label712: "Internetverbindung",
  label713: "Architektur des ARM- oder ARM64-Prozessors",
  label714: "2 GB RAM (4 GB empfohlen oder mehr)",
  label715: "URL des Downloadbereichs kopieren",
  label716: "URL des Webplayers kopieren",
  label717: "Support",
  label718: "Für <font color=#FFFFFFF'>Anleitungen</font> zur Verwendung der Plattform und <font color=#FFFFFFF'>häufig gestellte Fragen</font> besuchen Sie bitte",
  label719: "Hilfe und häufig gestellte Fragen",
  label720: "Weitere Optionen",
  label721: "Weitere Informationen",
  label722: "Zertifikat",
  label723: "URL des Downloadbereichs erfolgreich kopiert.",
  label724: "URL des Webplayers erfolgreich kopiert.",
  label725: "Aktualisieren, Änderungen rückgängig machen",
  label726: "Setzen Sie die Anpassung für alle Ihre Benutzer zurück.",
  label727: "Zertifikat herunterladen",
  label728: "Zertifikat_freedom",
  label729: "Information",
  label730: "Betriebssystem Mac OS Catalina (10.15.7) oder höher (auch mit älteren Versionen von Catalina kompatibel, erfordert jedoch manuelles Programmupdate)",
  label731: "Musik",
  label732: "Möchten Sie sich abmelden?",
  label733: "Ticketkontakt",
  label734: "WhatsApp-Kontakt",
  label735: "Benutzer vom Administrator gesperrt",
  label736: "Suspendieren",
  label737: "Aktivieren",
  label738: "Vom Administrator suspendiert",
  label739: "Der Benutzer wurde erfolgreich aktiviert.",
  label740: "Der Benutzer wurde erfolgreich suspendiert.",
  label741: "Fehler, beim Aktivieren des Benutzers ist etwas schief gelaufen.",
  label742: "Fehler, beim Suspendieren des Benutzers ist etwas schief gelaufen.",
  label743: "Lieblings-Playlists",
  label744: "Suspendieren von",
  label745: "Aktivieren von",
  label746: "Möchten Sie diesen Benutzer vorübergehend suspendieren?",
  label747: "Möchten Sie diesen Benutzer aktivieren?",
  label748: "App-Berechtigungen",
  label749: "Die Berechtigung wurde erfolgreich aktiviert.",
  label750: "Die Berechtigung wurde erfolgreich deaktiviert.",
  label751: "Fehler beim Aktivieren der Berechtigung.",
  label752: "Fehler beim Deaktivieren der Berechtigung.",
  label753: "PIN-Anforderung aktiviert",
  label754: "PIN-Anforderung deaktiviert",
  label755: "Fehler beim Aktivieren der PIN-Anforderung.",
  label756: "Fehler beim Deaktivieren der PIN-Anforderung.",
  label757: "PIN-Anforderung aktiviert",
  label758: "PIN-Anforderung deaktiviert",
  label759: "Hinzufügen",
  label760: "Die Playlist wurde erfolgreich zu den Favoriten hinzugefügt.",
  label761: "Nicht hinzugefügt, da die Playlist bereits in den Favoriten vorhanden ist.",
  label762: "Fehler, beim Hinzufügen der Playlist zu den Favoriten ist etwas schief gelaufen.",
  label763: "Hinzufügen...",
  label764: "Zu Favoriten hinzufügen",
  label765: "Alias",
  label766: "Fehler, das Passwort enthält Leerzeichen (nicht erlaubt).",
  label767: "Fehler, das Passwort muss bestätigt werden.",
  label768: "App-Berechtigungen",
  label769: "Lizenzen",
  label770: "Gesperrte Benutzer",
  label771: "Die App-Sprache wurde erfolgreich auf Portugiesisch geändert.",
  label772: "Fehler, beim Ändern der App-Sprache auf Portugiesisch ist etwas schief gelaufen.",
  label773: "Gesperrte Interpreten",
  label774: "Zu gesperrten Interpreten hinzufügen",
  label775: "Geben Sie den Namen des Interpreten ein, den Sie suchen möchten...",
  label776: "Der Interpret wurde erfolgreich zu den gesperrten Interpreten hinzugefügt.",
  label777: "Der Interpret wurde nicht hinzugefügt, da er bereits in den gesperrten Interpreten vorhanden ist.",
  label778: "Fehler, beim Hinzufügen des Interpreten zu den gesperrten Interpreten ist etwas schief gelaufen.",
  label779: "Der Interpret",
  label780: "wurde erfolgreich aus den gesperrten Interpreten entfernt.",
  label781: "Fehler, beim Entfernen des Interpreten aus dieser Liste ist etwas schief gelaufen.",
  label782: "Möchten Sie",
  label783: "von den gesperrten Interpreten entfernen?",
  label784: "wird aus den gesperrten Interpreten entfernt.",
  label785: "Einstellung",
  label786: "Ungültiges Abonnement. Wenden Sie sich an den Dienstanbieter",
  label787: `Fehler, Sie haben das Limit der Vertragskonten erreicht.`,
  label788: `Fehler, beim Erstellen des Benutzers ist ein Fehler aufgetreten.`,
  label789: "Sie haben keine Berechtigung, Benutzer zu erstellen, zu bearbeiten oder zu löschen.",
  label790: "Sie haben keine Berechtigung, auf Lizenzen zuzugreifen oder diese einzusehen.",
  label791: "Sie haben keine Berechtigung, die Personalisierung für alle Benutzer zurückzusetzen.",
  label792: "Sie haben keine Berechtigung, die Lieblingsplaylist eines Benutzers anzusehen.",
  label793: "Sie haben keine Berechtigung, die Lieblingsplaylist eines Benutzers hinzuzufügen oder zu entfernen.",
  label794: "Sie haben keine Berechtigung, den Playlist-Planer eines Benutzers anzusehen.",
  label795: "Sie haben keine Berechtigung, Playlists im Playlist-Planer eines Benutzers hinzuzufügen, zu bearbeiten oder zu löschen.",
  label796: "Sie haben keine Berechtigung, den Anzeigen-Planer eines Benutzers anzusehen.",
  label797: "Sie haben keine Berechtigung, Anzeigen im Anzeigen-Planer eines Benutzers hinzuzufügen, zu bearbeiten oder zu löschen.",
  label798: "Sie haben keine Berechtigung, Benutzer zu klonen.",
  label799: "Sie haben keine Berechtigung, auf die Benutzereinstellungen zuzugreifen.",
  label800: "Sie haben keine Berechtigung, auf die Benutzerpersonalisierung zuzugreifen.",
  label801: "Sie haben keine Berechtigung, auf die App-Berechtigungen eines Benutzers zuzugreifen.",
  label802: "Sie haben keine Berechtigung, einen Benutzer zu sperren.",
  label803: "Sie haben keine Berechtigung, die Playlists des Administrators anzusehen.",
  label804: "Sie haben keine Berechtigung, die Playlists des Administrators zu erstellen, zu bearbeiten oder zu löschen.",
  label805: "Sie haben keine Berechtigung, die Lieblingsplaylists des Administrators anzusehen.",
  label806: "Sie haben keine Berechtigung, die Lieblingsplaylists des Administrators hinzuzufügen oder zu entfernen.",
  label807: "Sie haben keine Berechtigung, die Stile des Administrators anzusehen.",
  label808: "Sie haben keine Berechtigung, die Stile des Administrators zu erstellen, zu bearbeiten oder zu löschen.",
  label809: "Sie haben keine Berechtigung, auf den Musikmanager zuzugreifen.",
  label810: "Sie haben keine Berechtigung, gesperrte Interpreten anzusehen.",
  label811: "Sie haben keine Berechtigung, gesperrte Interpreten hinzuzufügen oder zu entfernen.",
  label812: "Sie haben keine Berechtigung, hochgeladene Anzeigen anzusehen.",
  label813: "Sie haben keine Berechtigung, Anzeigen hochzuladen oder zu löschen.",
  label814: "Sie haben keine Berechtigung, auf Support und Downloads zuzugreifen.",
  label815: "Auswahl bearbeiten",
  label816: "Alles auswählen",
  label817: "Aufseher",
  label818: "Aufseher erstellen oder bearbeiten und ihre Aktionen einschränken.",
  label819: "Berechtigungen wurden erfolgreich gespeichert.",
  label820: "Fehler, beim Speichern der Berechtigungen ist etwas schiefgelaufen.",
  label821: "Aufseher vom Administrator suspendiert.",
  label822: "Der Stil wurde für die ausgewählten Lieder geändert.",
  label823: "Die ausgewählten Lieder wurden auf den Standardstil geändert.",
  label824: "Die ausgewählten Lieder wurden zu den ausgewählten Playlists hinzugefügt.",
  label825: "Die ausgewählten Lieder wurden erfolgreich als explizit markiert.",
  label826: "Die ausgewählten Lieder wurden erfolgreich als explizit abgemeldet.",
  label827: "Die ausgewählten Lieder wurden erfolgreich als 'gefällt mir' markiert.",
  label828: "Die ausgewählten Lieder wurden erfolgreich als 'gefällt mir' abgemeldet.",
  label829: "Die ausgewählten Lieder wurden erfolgreich als 'nicht gewünscht' markiert.",
  label830: "Änderungen an den ausgewählten Liedern wurden erfolgreich gelöscht.",
  label831: "Fehler, beim Ändern des Stils der Lieder ist etwas schiefgelaufen.",
  label832: "Fehler, beim Ändern der Lieder auf den Standardstil ist etwas schiefgelaufen.",
  label833: "Fehler, beim Hinzufügen der Lieder zu den Playlists ist etwas schiefgelaufen.",
  label834: "Fehler, beim Markieren der Lieder als explizit ist etwas schiefgelaufen.",
  label835: "Fehler, beim Abmelden der Lieder als explizit ist etwas schiefgelaufen.",
  label836: "Fehler, beim Markieren der Lieder als 'gefällt mir' ist etwas schiefgelaufen.",
  label837: "Fehler, beim Abmelden der Lieder als 'gefällt mir' ist etwas schiefgelaufen.",
  label838: "Fehler, beim Markieren der Lieder als 'nicht gewünscht' ist etwas schiefgelaufen.",
  label839: "Fehler, beim Löschen der Änderungen an den Liedern ist etwas schiefgelaufen.",
  label840: "Wählen Sie eine Aktion...",
  label841: "Wird angewendet...",
  label842: "Anwenden",
  label843: "Wechseln zu einem Benutzerstil",
  label844: "Wechseln zum Standardstil",
  label845: "Zu einer manuellen Playlist hinzufügen",
  label846: "Als explizit markieren",
  label847: "Als explizit abmelden",
  label848: "Als 'gefällt mir' markieren",
  label849: "Als 'gefällt mir' abmelden",
  label850: "Als 'nicht gewünscht' markieren",
  label851: "Änderungen löschen",
  label852: "Ausgewählte Lieder",
  label853: "Durchzuführende Aktion",
  label854: "Berechtigungen",
  label855: "Fehler, beim Bearbeiten des Aufsehers ist etwas schiefgelaufen",
  label856: "Aufseher bearbeiten",
  label857: "Beobachtung",
  label858: "Fehler, das Feld Benutzername ist erforderlich",
  label859: "Fehler, Sie haben die erlaubte Anzahl an Aufsehern erreicht.",
  label860: "Fehler, beim Erstellen des Aufsehers ist etwas schiefgelaufen.",
  label861: "Aufseher erstellen",
  label862: "Berechtigungen zur Nutzung des Kontrollpanels, drücken Sie die Speichertaste, um die Änderungen zu übernehmen.",
  label863: "Die Anzahl der Aufseher, die Sie erstellen können, ist auf 5 begrenzt.",
  label864: "Geben Sie den Namen des zu suchenden Aufsehers ein...",
  label865: "Fehler, beim Löschen des Aufsehers ist etwas schiefgelaufen",
  label866: "Möchten Sie den Aufseher löschen?",
  label867: "Der Aufseher wird gelöscht",
  label868: "Möchten Sie diesen Aufseher vorübergehend suspendieren?",
  label869: "Möchten Sie diesen Aufseher aktivieren?",
  label870: `Vorbereitung der Ankündigung...`,
  label871: `Mit KI erstellen`,
  label872: ``,
  label873: ``,
  label874: ``,
  label875: ``
};

export default de_DE;